import React, { Fragment, useEffect, useRef } from 'react';
import { functions } from '../../functions';

export const Wheel = () => {
    functions.hide_dropdown(1);  

 

 

return(
<Fragment>

<div className="step_titel">
<div className="doc_width">
     <h1>Spin The Wheel</h1>  

</div>
</div>

<div className="doc_width shadow_bg">
         
     <div id="rightcol" className="package_box package_page">
	
	 <div className="wheel-wrap">
        <div className="user-status"></div>
<div className="text-center">		
		
		
        <img className="lights mobile_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/lights.png" />		
        <img className="lights lights_img dasktop_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/lights2.png" />		
		
		
        <img className="button mobile_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/button.png" useMap="#wheelmap"/>
		<img className="button button_img dasktop_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/button2.png" useMap="#wheelmap"/>
		
		
        <img className="marker mobile_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/marker.png" />
		<img className="marker marker_img dasktop_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/marker2.png" />		
		
         <img className="wheel mobile_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/wheel.png" />
        <img className="wheel wheel_img dasktop_none_new" src="http://dev.propennyauction.com/games/wheel_js/images/wheel2.png" />		

  
		
		
		
		
		
		
		
		<map name="wheelmap">
            <area shape="circle" coords="315,322,70" href="#" alt="Click to spin wheel!" />
        </map>
    
        <div className="msg-wrap" style={{display: 'none'}}>
            <div className="msg">
            </div>
            <img className="close" src="http://dev.propennyauction.com/games/wheel_js/images/close.png" />
        </div>

    </div>
	
	
	
</div> 
	 
	 
	 
	 
	 
	 
	 
	 
	 </div>
  </div> 

</Fragment>
);	

}