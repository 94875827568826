import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { functions } from "../../functions";
import { useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";

import { CartCountContext } from "../../Router";

function Index(props) {
  const { cat_id } = useParams();

  const [products, setProducts] = useState([]);
  const [category, setcategory] = useState([]);
  const [progress, setProgress] = useState(0);

  const userData = useSelector((userData) => userData);

  const { cartCnt, setcartCnt } = useContext(CartCountContext);

  console.log(cartCnt);

  useEffect(() => {
    setProgress(10);
    getProducts();
    setProgress(100);
  }, [cat_id]);

  const getProducts = async () => {
    //console.log(`page loading : `);
    let req = await axios.get(
      `http://dev.propennyauction.com/api/products.php?cat_id=${cat_id}`
    );
    setProgress(30);
    let res = await req;
    setProgress(50);
    //console.log(res.data.categories);
    setProducts(res.data.data);
    setcategory(res.data.categories);
    setProgress(100);
    //props.add_products(res.data.data);
  };

  const addItemDataToServer = () => {};

  function saving_function(rrp, buy_now) {
    let perc = ((buy_now / rrp) * 100).toFixed(0);
    return 100 - perc;
  }

  const add_to_cart = (product) => {
    if (userData.login.user_id > 0) {
      //e.preventDefault()
      //await props.add_to_cart(product)
      //await	props.add_cart_length(props.cart.length)
      let req = axios.post(
        "http://dev.propennyauction.com/api/cart.php?action=add",
        {
          user_id: userData.login.user_id,
          product_id: product.id,
          quantity: 1,
          price: product.buy_now,
          shipping: "asas",
        }
      );
      req
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log("err:" + err);
        });
      functions.flash_msg(
        '<div class="center_popup">Product added into Cart</div>'
      );
      setcartCnt(cartCnt + 1);
    } else {
      alert("You must login to buy");
    }
  };

  return (
    <Fragment>
     <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

      <div className="category-outer">
        <div className="category">
          <div className="doc_width">
            <ul>
              <NavLink to="/store/">
                <li className="star-icon">
                  <img src="/images/star-gray.png" alt="" border="0" />
                  <span>{products.length}</span>
                </li>
              </NavLink>
              {category.map((category) => (
                <li
                  className={category.id == cat_id ? "active" : ""}
                  key={category.id}
                >
                  <NavLink onClick={getProducts} to={`/store/${category.id}`}>
                    {category.category_name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="doc_width">
        <div class="step_titel mar_b_0">
          <h1>Store </h1>
        </div>

        <ul className="horizontal-bid-list">
          {products.map((product) => {
            return (
              <li
                className="store"
                id={`auction-${product.id}`}
                key={product.id}
                title={product.title}
              >
                <div className="thumb clearfix">
                  <NavLink to={`/products/view/${product.id}`}>
                    <img src={product.img} className="img_type_3" />
                  </NavLink>
                </div>
                <h3>
                  <NavLink to={`/products/view/${product.id}`}>
                    {" "}
                    {product.title}{" "}
                  </NavLink>
                </h3>

                <div className="row row_price text-center">
                  <div className="padd_lr_20">
                    <span className="bid-price">
                      {" "}
                      {functions.currency(product.buy_now)}{" "}
                    </span>
                    <p>
                      <del> {functions.currency(product.rrp)}</del>
                    </p>
                    <p>
                      {" "}
                      Save over {saving_function(
                        product.rrp,
                        product.buy_now
                      )}%{" "}
                    </p>
                  </div>
                </div>
                <p>
                  {" "}
                  Location: <strong>Dubai, UAE</strong>{" "}
                </p>
                <div className="auc_btm_content blink">
                  <div className="bidbtn bid-button">
                    <a
                      className="b-login-new"
                      onClick={() => add_to_cart(product)}
                    >
                      <span>
                        Add to cart<span> </span>
                      </span>
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    products: state.cartReducer.products,
    cart: state.cartReducer.cart,
    userData: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    add_products: (products) =>
      dispatch({ type: "ADD_PRODUCTS", payload: { products: products } }),
    add_to_cart: (product) =>
      dispatch({ type: "ADD_TO_CART", payload: { product: product } }),
    add_cart_length: (cart_length) =>
      dispatch({
        type: "ADD_CART_LENGTH",
        payload: { cart_length: cart_length },
      }),
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(Index);
export default Index;
