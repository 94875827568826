import React, { Fragment, useEffect, useState } from 'react';
import  { NavLink  } from 'react-router-dom';
import { UserMenu } from '../../elements/user_menu';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { functions } from '../../functions';


const StoreOrders = () => {

  const [auctions, setAuctions] = useState([]);
  const Data = useSelector((data) => data);

  useEffect(() => {
    PageData();
 
  }, []);


  const PageData = async () => {
    let req = await axios.get(" http://dev.propennyauction.com/api/store_orders.php?user_id=" + window.localStorage.getItem("user_id")  );
    let res = await req;
    if (res.data.success) {
      setAuctions(res.data.data);
    }
  }


return(
<>


<UserMenu /> 


<div className="doc_width shadow_bg">       
<div className="step_titel">
  <h1>Store Orders</h1>     
</div>
 		<div className="table-responsive">          
     <div id="rightcol"> 
   


          <table className="results store_order hide1" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <tbody><tr>
              <th> ID</th>
               <th> Price  </th>
              <th> Date Won </th>
              <th> Status </th>
              <th> Action </th>
             </tr>
                        			
            {

auctions.map((auction, i) => {
  return (
    <React.Fragment key={i}>
      <tr>
        <td className="normal_bg padd" colSpan="">{auction.id} </td>
 
         <td className="normal_bg padd" colSpan="">{ functions.currency(  auction.price ) } </td>
        <td className="normal_bg padd" colSpan="">{ auction.created } </td>
        <td className="normal_bg padd" colSpan="">{ auction.status_name } </td>
        <td className="normal_bg padd" colSpan="">
        <NavLink to={`/store/orders/${auction.id}`} className="btn_new  animated-btn animated-1">View Details</NavLink>

            </td>
       </tr>


    </React.Fragment>
  )
})
}
          </tbody></table>
          
                    
        </div>
       </div>  
</div>
</>
);  
}

export default StoreOrders;