import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { functions } from '../../functions';
import { listen } from 'socket.io';
import Steps from '../../elements/Steps';
import Category_header from '../../elements/Category_header';

const ENDPOINT = 'https://dev.propennyauction.com:3002';

export const Index = () => {
	//functions.hide_dropdown(1);
	const userData = useSelector((userData) => userData);
	let { auction_type } = useParams();

	const [items, setItems] = useState([]);
	const [soldCount, setSoldCount] = useState(0);
	const timer = useRef(null);

	useEffect(() => {
		const getItems = async () => {
			console.log(`page loading index useEffect : `);
			let req = await axios.get(
				'http://dev.propennyauction.com/api/index.php?type=' + auction_type
			);
			let res = await req;
			//console.log(res);
			if (res.data.success) {
				setItems(res.data.data);
				console.log(res.data.data);
				timer.current = setInterval(() => {
					setItems((items) => {
						return items.map((i) => {
							return { ...i, second_left: i.second_left - 1 };
						});
					});
				}, 1000);
			}

			const socket = window.io.connect(ENDPOINT);
			socket.on('connect', (data) => {
				console.log('socket connected');
			});

			socket.on('push', (data) => {
				push_data(data);
			});

			socket.on('error', (data) => {
				if (!socket.connected) {
					console.log('NOT CONNECTED node ');
				}
			});
			if (!socket.connected) {
				console.log('NOT CONNECTED node ');
			}
		};
		getItems();

		return () => {
			clearInterval(timer.current);
		};
	}, [auction_type, soldCount, timer]);

	function push_data(data) {
		setItems((items) => {
			return items.map((i) =>
				i.id === data.id
					? {
							...i,
							second_left: data.s,
							price: data.p,
							cs: data.cs,
							username_leader: data.u,
					  }
					: i
			);
		});

		const listItem = document.querySelector(`li#auction-${data.id}`);
		if (listItem) {
			listItem.classList.add('bid_animation');
			setTimeout(() => {
				listItem.classList.remove('bid_animation');
			}, 1000);
		}
		if (data.cs === 1 || data.cs == 1 || data.cs == '1') {
			const listItem = document.querySelector(`li#timer_${data.id}`);
			if (listItem) {
				listItem.innerHTML = 'SOLD';
			}
		}
		if (data.cs === 1 || data.cs == 1 || data.cs == '1') {
			//console.log('removing: ' + data.id)
			//let duplicateItems = [...items];
			//duplicateItems.splice(data.id, 1);
			//setItems(duplicateItems);
			//setSoldCount(soldCount+1);
		}
	}

	function secondsToTime(secs, cs) {
		if (cs === 1 || cs == 1 || cs == '1') {
			return 'SOLD';
		}
		if (secs < 0 && cs !== 1) {
			return '00:00:00 ';
		}
		let hours = Math.floor(secs / (60 * 60));
		if (hours < 10) hours = '0' + hours;

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
		if (minutes < 10) minutes = '0' + minutes;

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
		if (seconds < 10) seconds = '0' + seconds;

		let obj = hours + ':' + minutes + ':' + seconds;
		return obj;
	}

	const renderSteps = () => {
		if (auction_type >= 0) {
			return <Steps />;
		} else {
		}
	};
	const rendercategories = () => {
		if (auction_type === undefined) {
			return <Category_header items={items} />;
		}
	};

	return (
		<>
			{renderSteps()}
			{rendercategories()}

			<div className='auction_type hide'>
				<ul>
					<li className='penny' title='Penny Auction'>
						Penny Auction <span></span>
					</li>
					<li className='seat' title='Seat Auction'>
						Seat Auction <span></span>
					</li>
					<li className='unique' title='Unique Auction'>
						Unique Auction <span></span>
					</li>
					<li className='ebay' title='Ebay Auction'>
						Ebay Auction <span></span>
					</li>
					<li className='live' title='Live Auction'>
						Live Auction <span></span>
					</li>
				</ul>
			</div>

			<div className='doc_width'>
				<div className='step_titel mar_b_0'>
					<h1> {auction_type >= 0 ? '' : auction_type} Auctions</h1>
				</div>

				<ul className={`horizontal-bid-list ${auction_type}`}>
					{items.map((auction) => {
						return (
							<li
								id={`auction-${auction.id}`}
								key={auction.id}
								className={`auction-item  auction_${auction.id}`}
								rel={`auction_${auction.id}`}
								title={auction.title}
							>
								{auction_type > 0 && (
									<i className='live_icon'>
										<span></span>
									</i>
								)}

								<font></font>
								<font></font>
								<font></font>
								<font></font>
								<span class='close_sticker'></span>
								<div className='thumb clearfix'>
									<NavLink to={`/auctions/view/${auction.id}`}>
										<img src={auction.img} className='img_type_3' />
									</NavLink>
								</div>
								<h3>
									<NavLink to={`/auctions/view/${auction.id}`}>
										{' '}
										{auction.title}{' '}
									</NavLink>
								</h3>
								<div className='content-flash'>
									<div className='row row_price'>
										<div className='padd_lr_20'>
											<div className='price_outer'>
												{auction_type > 0 && (
													<div className='price_arrow'>
														<div id='pricear'>
															<span className='arrow1 arrow_up ar1'></span>
															<span className='arrow1 arrow_down ar1'></span>
														</div>
													</div>
												)}

												<span
													className='bid-price'
													id={`bid_price_${auction.id}`}
												>
													${auction.price}
												</span>
											</div>
										</div>
									</div>

									<div className='row row_username'>
										<label className='username_highlight'>
											<span
												className='username bid-bidder'
												id={`bid_bidder_${auction.id}`}
											>
												{auction.username_leader}
											</span>
										</label>
									</div>

									<span id={`timer_${auction.id}`} className='timer countdown'>
										<span id={`second_left_count_${auction.id}`}>
											{secondsToTime(auction.second_left, auction.cs)}
										</span>
									</span>
								</div>

								<div className='auc_btm_content blink'>
									{userData.login.user_id > 0 ? (
										<React.Fragment>
											<div className=' btn_new bidbtn bid-button'>
												<NavLink
													className='b-login-new'
													to={`/auctions/view/${auction.id}`}
												>
													View
												</NavLink>
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div className=' btn_new bidbtn bid-button'>
												<NavLink className='b-login-new' to='/signup'>
													{' '}
													Bid Now{' '}
												</NavLink>
											</div>
										</React.Fragment>
									)}

									<div class='buynow_btn_outer'>
										<a class='buynow-btn f_l' href='#'>
											<strong></strong>
											<label> {functions.currency(auction.buy_now)} </label>
										</a>

										<a class='buynow-btn view_btn f_r' href='#'>
											<strong></strong>
											<label>View</label>
										</a>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);
};
