import React, { useState, useEffect } from "react";
import { Home } from "./view/auctions/Home";
import LoadingBar from "react-top-loading-bar";

import axios from "axios";
import Router from "./Router";
import { Header } from "./elements/Header";
import { Footer } from "./elements/Footer";
import { useDispatch } from "react-redux";
import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const [cartCnt, setcartCnt] = useState(2);
  const [topLoading, settopLoading] = useState(0);
  const [progress, setProgress] = useState(0);

  if (window.localStorage.getItem("user_id") > 0) {
    let user_id = window.localStorage.user_id;
    let username = window.localStorage.username;
    let email = window.localStorage.email;

    let SET_USER_ID = "SET_USER_ID";
    let SET_USERNAME = "SET_USERNAME";
    let SET_EMAIL = "SET_EMAIL";
    dispatch({ type: SET_USER_ID, payload: user_id });
    dispatch({ type: SET_USERNAME, payload: username });
    dispatch({ type: SET_EMAIL, payload: email });
  }

  useEffect(() => {
    setProgress(20);
    getCartCount();
    console.log("app js useeffect ");
  }, []);

  const getCartCount = () => {
    setProgress(30);

    axios
      .get(
        "http://dev.propennyauction.com/api/cart.php?action=count&user_id=" +
          window.localStorage.getItem("user_id")
      )
      .then((res) => {
        setProgress(50);
        setcartCnt(res.data.data.cart_cnt);
        setProgress(100);
      })
      .catch((err) => {
        setProgress(50);
        console.log("err: header element" + err);
        setProgress(100);
      });
  };

  return (
    <div className="main_content">
      
      <Header
        cartCnt={cartCnt}
        topLoading={topLoading}
        settopLoading={settopLoading}
      ></Header>
      <Router cartCnt={cartCnt} setcartCnt={setcartCnt}></Router>
      <Footer></Footer>
    </div>
  );
};

export default App;
