import React from 'react';

function Steps(props) {
    return (
        <div className="home-steps">
			<img src="/images/main-bnr.jpg" alt="" />
      <div className="doc_width">
	  
        <div className="step">
		<label>01</label>
		<div className="info-text">
		<div className="small_titele">
		<a href="signup">Join Now</a>
		</div>
		<font>Join now and get 5 free bids</font>
		</div>
		</div> 
		
        <div className="step">
		<label>02</label>
		<div className="info-text">
		<div className="small_titele">
		<a href="packages">Purchase Bids</a>
		</div>
		<font>Purchase the bids package that suits you most</font>
		</div>
		</div>

        <div className="step">
			<label>03</label>
			<div className="info-text">
			<div className="small_titele">
			<a href="home">Start Bidding</a>
			</div>
			<font>Choose the item you would like to bid on</font>
			</div>
		</div>
		
        <div className="step">
			<label>04</label>
			<div className="info-text">
			<div className="small_titele">
			Check Out
			</div>
			<font>Choose the item you would like to bid on</font>
			</div>
		</div>				
      </div>
	</div>
    );
}

export default Steps;