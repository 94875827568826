import React, { Fragment,useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

export const Penny_auction_guide = () => {
     window.scrollTo(0, 20)
     const [progress, setProgress] = useState(100);

return(
<Fragment>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />


<div className="doc_width shadow_bg inner_page">
		<div className="step_titel">
          <h1>Penny Auction Guide</h1>      
        </div> 
                
<div id="rightcol">

<div className="page_list">

<p>this is how it penny auction guide page </p>
<p>this is how it penny auction guide works page </p>

</div>

               
</div>
</div> 

</Fragment>
);	

}