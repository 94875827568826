import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { functions } from '../../functions';
import Gallery from 'react-grid-gallery';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';



let pin_city = '';
const Product_View = () => {
	const userData = useSelector(userData => userData);
	const { product_id } = useParams();
	const [pininfo, setPininfo] = useState();
	const [Auction_data, setAuction_data] = useState({});
	const [AuctionImages, setAuctionImages] = useState([]);
	const pinCodeRef = useRef();
	let history = useHistory();
	useEffect(() => {
		axios
			.get(
				`http://dev.propennyauction.com/api/product_detail.php?id=${product_id}`
			)
			.then((res) => {
				if (res.status == 200 && res.data.success) {
					setAuction_data(res.data.data);
					setAuctionImages(res.data.data.prd_img);

				}

			});

		return () => {
			//if (timer.current) {
			//clearInterval(timer);
			//}
		};


	}, []);

	const checkPinCode = () => {
		axios
			.get(
				`https://api.postalpincode.in/pincode/${pinCodeRef.current.value}`
			)
			.then((res) => {
				console.log(res.data[0])
				if (res.data[0].Status === "Success") {

					if (res.data[0].PostOffice[0].block != '') {
						pin_city = 'Cod Available at: ' + res.data[0].PostOffice[0].Block + ' ' + res.data[0].PostOffice[0].Circle
					}

				} else {
					pin_city = 'Invalid pincode / Cod not available'
				}
				setPininfo(pin_city);

			});
	}

	const add_to_cart = (product, is_buy_now) => {
		if (userData.login.user_id > 0) {
			 
			let req = axios.post("http://dev.propennyauction.com/api/cart.php?action=add", {
				user_id: userData.login.user_id,
				product_id: product.id,
				quantity: 1,
				price: product.buy_now,
				shipping: "asas"
			});
			req.then(res => {
				console.log(res.data);

			}).catch(err => {
				console.log('err:' + err);
			})
			functions.flash_msg('<div class="center_popup">Product added into Cart</div>');
			if (is_buy_now) {
				history.push('/cart')
			} else {

			}
		} else {

			functions.flash_msg('<div class="center_popup">You must login to buy</div>');
		}
	}

	const save_price = Auction_data.rrp - Auction_data.buy_now;
	const save_per = 100 - ((Auction_data.buy_now / Auction_data.rrp) * 100).toFixed(2);

	// tab-link
	function btm_tabs(e) {
		var all_tabs_ttl = document.querySelectorAll('.tab-link');
		for (const tab_link of all_tabs_ttl) {
			tab_link.classList.remove('current');
		}
		var all_tabs = document.querySelectorAll('.tab-content');
		for (const all_tab of all_tabs) {
			all_tab.style.display = 'none';
			all_tab.classList.remove('current');
		}
		e.target.classList.add('current');
		document.getElementById(e.target.getAttribute('dataTab')).style.display =
			'block';
	}







	return (

		<>
			<div id='container' className='auc-det'>
				<div className='lot_id hide'>
					<div className='doc_width'>Lot # {Auction_data.id} </div>
				</div>

				<div className='doc_width auc_details shadow_bg mar_t_20 store_view'>
					<div className='doc_width  shadow_bg_des mt-0'>
						<div id='auction-details'>
							<div className='col1'>
								<div className='content'>
									<div className='center_img auction-image'>
										<img
											id='imgPrincipal'
											className='img_type_3'
											src={Auction_data.img}
											alt=''
										/>
										<div style={{ clear: 'both' }}></div>
									</div>
									<div className='thumbs'>
										<Gallery images={AuctionImages} />,
										</div>
									<div className='thumbs hide'>
										{0 && Auction_data.prd_img &&
											Auction_data.prd_img.map((thumbs, i) => {
												return (
													<div className='margin_seg'>
														<div className='center_img_thumb'>
															<span key={i}>
																<img className='divThumb' src={thumbs.image} />{' '}
															</span>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>

							<div className='col2_col3'>
								<div className='col2'>

									<div
										className='content auction-item middlebox auction_11681'
										rel='auction_11681'
									>
										<div className='content_manage_hieght'>
											<div className='padd-15-all'>


												<div className='auc_row padd_20'>
													<h1>{Auction_data.title}</h1>
													<div className='timer'>
														<div className='label2 top_auc_price'>
															<strong>
																<p className='up_price up_price_store'>Current price</p>
																<font className='bid-price'>
																	{/* {functions.currency(Auction_data.price)} */}
																	${Auction_data.buy_now}
																</font>
															</strong>{' '}
															<font className='usd'>USD</font>{' '}
														</div>
														<div className='buynoprice'>
															<strong>
																{functions.currency(Auction_data.rrp)}
															</strong>

															<p>
																Save over , {functions.currency(save_price)}({' '}
																{save_per} % )
															</p>
														</div>
													</div>
												</div>

												<div className='auc_row delivery'>
													<div className='delivery_sec'>
														<span className="delivery_left">Delivery</span>
														<div className="delivery_right">
															<input type="text" ref={pinCodeRef} placeholder="Enter pincode"></input>
															<span className="buttons"><button class="btn_new animated-btn animated-1 small_btn" onClick={checkPinCode} >Check</button></span>
															<p> {Auction_data.delivery_info}</p>
															<p className="error"> {pininfo}</p>
														</div>
													</div>
												</div>

												<div className='auc_row  padd_20'>
													<div className='bid-now'>
														<div className='bid-button'>

															<button onClick={() => add_to_cart(Auction_data, 0)} className="b-login-new btn-cart-icon">Add to Cart</button>
															<button onClick={() => add_to_cart(Auction_data, 1)} className="b-login-new buynow btn-buy-icon">Buy now</button>

														</div>
													</div>

												</div>

												<div className='add_business'>
													{!Auction_data.in_watchlist ? (
														<p
															className='star_icon'
															style={{ cursor: 'pointer' }}
														>
															Add to Watchlist
														</p>
													) : (
														<p
															className='star_icon'
															style={{ cursor: 'pointer' }}
														>
															Remove from Watchlist
														</p>
													)}

													<p>
														<strong>613</strong> people Watching
														<font className='orange_text'>
															(added into watchlist)
														</font>
													</p>

													<p>
														Branded Products
														<strong> </strong>
													</p>
													<p>Ships in 24 hours</p>
												</div>

												<div id="reviews_ratings">
													<h1>Reviews & Ratings</h1>
													<div className="reviews_outer">
														<div className="reviews">
															<p><strong className="rating_no">5</strong><span>/5</span></p>
															<p>
																<font className="star_fill"></font>
																<font className="star_fill"></font>
																<font className="star_fill"></font>
																<font className="star_fill"></font>
																<font className="star_fill"></font>
															</p>
															<p className="rating_list">1 Rating and 0 Reviews</p>
														</div>
														<div className="reviews_write">
															<p>Have You Used this Product ?</p>
															<div className="reviews_write_btn"><a href="#">Rate and Write a Review</a></div>
														</div>
													</div>

												</div>

												<p className='err'></p>
												<span className='vat'></span>
											</div>

											<div className='view_inquiry'>
												<span className='f_l'>
													Easy Return <strong>7 days</strong>
												</span>

												<span className='f_r'>
													BuyNow Price <strong>{functions.currency(Auction_data.buy_now)}</strong>
												</span>
											</div>
										</div>
									</div>

									<div className='informations'>
										<div className='padd-15-all'>


											<li>
												<span>Product Id </span>
												<p>{Auction_data.id}</p>
											</li>
											<li>
												<span>Buy now price </span>
												<p>{functions.currency(Auction_data.buy_now)}</p>
											</li>
											<li>
												<span>Retail Price </span>
												<p>{functions.currency(Auction_data.rrp)}</p>
											</li>

											<li>
												<span>Payments </span>
												<p>
													Paypal, Credit card
												</p>
											</li>

											<li>
												<span>Shipping Fee </span>
												<p>{functions.currency(Auction_data.delivery_cost)}</p>
											</li>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

				<div style={{ clear: 'both' }}></div>

				<div className='doc_width  mar_t_20'>


					<div
						className='tab_con'
						style={{ overflow: 'hidden', background: '#fff' }}
					>
						<div className='padd-15'>
							<ul className='tabs_s'>
								<li
									className='tab-link left current'
									dataTab='tab-1'
									onClick={(e) => btm_tabs(e)}
								>
									Description
								</li>
								<li
									className='tab-link center_line'
									dataTab='tab-2'
									onClick={(e) => btm_tabs(e)}
								>
									Shipping Information
								</li>
								<li
									className='tab-link center_line'
									dataTab='tab-3'
									onClick={(e) => btm_tabs(e)}
								>
									Latest Prices
								</li>
								<li
									className='tab-link center_line'
									dataTab='tab-4'
									onClick={(e) => btm_tabs(e)}
								>
									Help
								</li>
								<li
									className='tab-link right'
									dataTab='tab-5'
									onClick={(e) => btm_tabs(e)}
								>
									Auction Information
								</li>
							</ul>

							<div id='tab-1' className='tab-content current'>
								{Auction_data.description}
							</div>

							<div id='tab-2' className='tab-content'>
								<h1>shipping details </h1>
							</div>

							<div id='tab-3' className='tab-content'>
								<h1>
									Latest Prices: <strong className='bid-price'>$2.24</strong>{' '}
								</h1>
							</div>

							<div id='tab-4' className='tab-content'>
								<h1>Help</h1>
							</div>

							<div
								id='tab-5'
								className='tab-content center_content tab_auction_info'
							>
								<h1>Auction Info</h1>
								<div className='row'>
									<p>
										Auction id: <strong>11681</strong>
									</p>
									<p>
										Auction Type:
										<strong>Penny Auction</strong>
									</p>
									<p>
										Auction Price: <strong className='bid-price'>$2.24</strong>
									</p>
								</div>

								<div className='row'>
									<p>
										Start Price: <strong>$1.00</strong>
									</p>
									<p>
										Price Increment: +<strong>$0.01</strong>{' '}
									</p>
									<p>
										Shipping Fee: <strong>$0.00</strong>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Product_View;