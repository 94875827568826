const State = {
	user_id:  0,
	username: '',
	user_email: ''
}

export const LoginReducer = (state=State,action) => {

let SET_USER_ID = 'SET_USER_ID';
let SET_USERNAME = 'SET_USERNAME';
let SET_EMAIL = 'SET_EMAIL';

switch(action.type){
case SET_USER_ID:
  return {...state, user_id: action.payload}	
case SET_USERNAME:
  return {...state, username: action.payload} 
case  SET_EMAIL:
return {...state, user_email: action.payload} 
default:
return {...state} 
}
}