import React from 'react';

function Category_header(props) {
    return (
        <div class="category-outer">
				<div class="category">
					<div class="doc_width">
						<ul>
							<li className="star-icon">
								<a  href="#"><img src="/images/star-gray.png" alt="" /></a>
								<span>50</span>
							</li>	
							<li><a  href="#">Apple </a></li>	
							<li className="active"><a  href="#">Samsung </a></li>
							<li><a  href="#">Redmi </a></li>
							<li><a  href="#">Electronics & Computer</a></li>
							<li><a  href="#">Health & Beauty</a></li>
							<li><a  href="#">Kitchen & Dining</a></li>
							<li><a  href="#">Cloths</a></li>	
							<li><a  href="#">Fashion</a></li>	
							<li><a  href="#">Sports</a></li>
							<li><a  href="#">Perfume</a></li>																																									
						</ul>
					</div>
				</div>
			</div>
    );
}

export default Category_header;