import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { NavLink,useHistory } from 'react-router-dom';
import { UserMenu } from '../../elements/user_menu';


function StoreOrderView(props) {

    window.scrollTo(0, 0)
    const { order_id } = useParams();
    let history = useHistory();
    const [orderInfo, setorderInfo] = useState({});
    const [product, setProduct] = useState([]);
    const Data = useSelector((data) => data);

    useEffect(() => {
        PageData();
    }, []);


    const PageData = async () => {


        axios.get("http://dev.propennyauction.com/api/store_order_detail.php?order_id=" + order_id)
            .then((res) => {
                setorderInfo(res.data.order)
                setProduct(res.data.data)
            })
            .catch((err) => {
                console.log('error ', err)
            })


    }

    return (

        <>


            <UserMenu />


            <div className="doc_width shadow_bg">
                <div className="step_titel">
                    <h1>Store Orders Detail</h1>
                </div>
                <NavLink class="btn_new left-animated  btn-blue small_btn" to="/store_orders"> {'<< Back'} </NavLink>
                <div className="table-responsive">
                    <div id="rightcol">
                        <table className="results store_order" width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody><tr>
                                <th>
                                    <p className="text-uppercase">Order Placed</p>
                                    <p>{orderInfo.order_created} </p>
                                </th>
                                <th>
                                    <p className="text-uppercase">Total</p>
                                    <p>{orderInfo.order_total}</p>
                                </th>
                                <th>
                                    <p className="text-uppercase">Ship To</p>
                                    <p>
                                        <a href="#">Your Self</a>
                                    </p>
                                </th>
                                <th className="text-right">
                                    <p className="text-uppercase">Order # 205-2004521-{orderInfo.order_id}</p>
                                    <p><a href="#">View Order Details</a> | <a href="#">Invoice</a></p>
                                </th>
                            </tr>

                                {
                                    product.map((order_info) => {
                                        return (
                                            <tr key={order_info.id}>
                                                <td colSpan="3">
                                                    <h2>{order_info.status_name}</h2>
                                                    <div className="store_order_des">
                                                    <img src={order_info.img} className='img_orders' width="50" /><br></br>
                                                        <div>
                                                            <a href="#">{order_info.title}</a><br></br>
                                                            Return window closed on 16-Apr-2021<br></br>
                                                             <NavLink class="btn_new left-animated  btn-blue" to="/store"> Continue Shopping </NavLink>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right store_order_right">
                                                    <p><a className="gray_btn" href="#"> Leave seller feedback </a></p>
                                                    <p><a className="gray_btn" href="#"> Write a product review </a></p>
                                                    <p><a className="gray_btn" href="#"> Achive order </a></p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }





                            </tbody>

                        </table>





                    </div>
                </div>
            </div>
        </>
    );
}

export default StoreOrderView;