import React, { Fragment,useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

function Privacy(props) {

    window.scrollTo(0, 0)
	const [progress, setProgress] = useState(100);

    return (
        <>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

        
        <div className="doc_width shadow_bg inner_page">
		<div className="step_titel">
        	<h1>Privacy Policy</h1>        
        </div>            
        <div id="rightcol">
        
        			
<p>
	This statement sets forth the Privacy Policy of propennyauction for the use of the propennyauction.com website (‘Site’, ‘website, ’we’,’propennyauction’). We respect your privacy and are committed to protecting personally identifiable information you may provide us through our website. We have adopted this Privacy Policy to explain what information may be collected through our website, and how we use this information.</p>
<ul>
	<li>
		<strong>What Information Do We Collect?</strong></li>
</ul>
<p>
	We will collect personal identification information from users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information. However, that may prevent them from engaging in certain Site related activities Our website generally collects two kinds of information about you:<br/>
	(i) information provided by you that personally identifies you; and<br/>
	(ii) information that the website automatically collects related to your actions when visiting our website.</p>
<ul>
	<li>
		<strong>Personally Identifiable Information</strong></li>
</ul>
<p>
	Our definition of personally identifiable information includes any information that may be used to specifically identify or contact you, including, your name, shipping address, billing address, phone number, company name and email address. As a general policy, we do not automatically collect your personally identifiable information when you visit the Site. In certain circumstances, propennyauction may request this type of information when required to participate in the particular activity, realize a benefit we may offer, or gain access to certain content on our website.</p>
<ul>
	<li>
		<strong>Non-Personally Identifiable Information</strong></li>
</ul>
<p>
	Our definition of non-personally identifiable information is any information that does not personally identify you. Non-personally identifiable information can include certain personally identifiable information that has been de-identified; that is, information that has been rendered anonymous. propennyauction obtains non-personally identifiable information about you from information that you provide us, either separately or together with your personally identifiable information. We also automatically collect certain non-personally identifiable information from you when you access our website. This information can include, among other things, IP addresses, the type of browser you are using (e.g., Internet Explorer, Firefox, Safari, etc.), the third party website from which your visit originated, the operating system you are using (e.g., Vista, Windows XP, Mac OS, etc.), the domain name of your Internet service provider, the search terms you used to find our website, etc.</p>
<ul>
	<li>
		<strong>How This Information May Be Used</strong></li>
</ul>
<p>
	The personally identifiable information you submit to us is generally used to carry out your requests, respond to your inquiries, better serve you, or in other ways naturally associated with the circumstances in which you provided the information. We may also use this information to later contact you for a variety of reasons, including for customer service purposes and/or to provide you promotional information for our products. We may also use your personal information to send you marketing updates but only ever in accordance with your preferences (as detailed in the next section). We do not sell your details to third party marketing companies. propennyauction collects and uses users’ personal information for the following purposes:</p>
<p>
	o To improve customer service: Your information helps us respond more effectively to your customer service requests and support needs.</p>
<p>
	o To personalize user experience: We may use information in the aggregate to understand how our users as a group use the services and resources provided on our Site.</p>
<p>
	o To improve our Site: We continually strive to improve our website offerings based on the information and feedback we receive from you.</p>
<p>
	o To process transactions: We may use the information users provide about themselves when placing an order only to provide better service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</p>
<p>
	o To administer contests, promotions, surveys or other site features: To send Users information they agreed to receive, about topics we think will be of interest to them.</p>
<p>
	o To send periodic emails: The email address that users provide for order processing will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions</p>
<p>
	o To promote through social media: The winning bidder’s username, or partially anonymous username, and savings details may be posted on official propennyauction social media accounts.</p>
<p>
	&nbsp;</p>
<ul>
	<li>
		<strong>Web browser cookies</strong></li>
</ul>
<p>
	Our site uses 'cookies', which are files that are stored on your computer that can be retrieved to assist in customizing your experience with the online service, the information saved supporting the functionality of the site. Our site uses cookies to keep track of how often you visit our site, previous purchases and to deliver content specific to your interests. Most browsers are automatically set to accept cookies but usually you can alter the settings of your browser to prevent automatic acceptance. If you choose not to receive cookies, you may still use most of the features of our website, including the ability to partake in auctions.</p>
<ul>
	<li>
		<strong>How we protect your information</strong></li>
</ul>
<p>
	We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
<ul>
	<li>
		<strong>Usernames and Passwords</strong></li>
</ul>
<p>
	You are solely responsible in all respects for all use of, and for protecting the confidentiality of any username and passwords that may be given to you or selected by you for use on our site. You may not share these or transfer them to any third parties. You must notify us immediately of any unauthorized use of them or any other breach of security regarding our website that comes to your attention at info@propennyauction.com. propennyauction may</p>
<ul>
	<li>
		<strong>Changes To This Agreement</strong></li>
</ul>
<p>
	We reserve the right, at our sole discretion, to modify or replace the privacy policy by posting the updated terms on the Site along a notification on the main page of the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Privacy Policy. Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use of the Site immediately.</p>
<ul>
	<li>
		<strong>Your Acceptance of These Terms</strong></li>
</ul>
<p>
	By registering an account on propennyauction.com, you are confirming that you have read and agree with this Privacy Policy.</p>

                       
        </div>
        </div> 
        
        </>
    );
}

export default Privacy;