import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { PayPalButton } from "react-paypal-button-v2";
 import { NavLink,useHistory  } from 'react-router-dom';


import Checkout from '../../paypal/checkout';
import { functions } from '../../functions';
import { CartCountContext } from '../../Router';

// import "./cart.scss"
//import './style.css'


const Cart = (props) => {
  const [shipping, setShipping] = useState(0)
  const [total, setTotal] = useState(0)
  const [products, setProducts] = useState([])
  let history = useHistory();

  const userData = useSelector(userData => userData);
  const { cartCnt,  setcartCnt  } = useContext(CartCountContext);

  useEffect(() => {
    const getProducts = async () => {
      //console.log(`page loading : `);
      let req = await axios.get(`http://dev.propennyauction.com/api/cart_products.php?user_id=${userData.login.user_id}`);
      let res = await req;
      console.log('get page data', res);
      setProducts(res.data.data)
      let subTotal = res.data.data.reduce((total, amount) => total + parseInt(amount.price, 10), 0);
      setTotal(subTotal)

      //props.add_products(res.data.data);
    }
    getProducts()
    console.log('cart.js products')
    console.log(products)

  }, []);

  const remove_from_cart = (cartItem, index) => {
    //await props.remove_from_cart(cartItem.id);
    //await props.add_cart_length(props.cart.length)

    let duplicateProducts = [...products];
    duplicateProducts.splice(index, 1);
    setProducts(duplicateProducts);
    functions.flash_msg('<div class="center_popup">Cart updated Successfully</div>');


    let req = axios.post("http://dev.propennyauction.com/api/cart.php?action=delete", {
      cart_id: cartItem.id
    });
    req.then(res => {
      console.log(res.data.data);
      setcartCnt(cartCnt-1)
    }).catch(err => {
      console.log('err:' + err);
    })
    //window.location.reload();
  }
  const process_test_pay = () => {
    //await props.remove_from_cart(cartItem.id);
    //await props.add_cart_length(props.cart.length)

    if (    window.confirm(`Are you sure, you want to pay ${total} `) )  {
      let req = axios.post("http://dev.propennyauction.com/api/cart.php?action=complete", {
        user_id: userData.login.user_id,
        cart_id: 0
      });
      req.then(res => {
        //console.log(res.data.data);
        //functions.flash_msg('Cart updated Successfully');
        //return <Redirect to="/store_orders"/>;
        setcartCnt(0)
        history.push('/store_orders')  
      

      }).catch(err => {
        console.log('err:' + err);
      })
    }

  }






  return (


    <div className="doc_width ">
      <div class="step_titel mar_b_0"><h1>Store </h1></div>


      <div className="cart_left shadow_bg f_l">
        <header className="cart-header">
          <strong>Items in Your Cart</strong>
        </header>
        <div className="cart_header">
          <div className="item_details">Item(s) Details</div>
          <div className="quantity">Quantity</div>
          <div className="amount">Amount</div>
        </div>



        <div class="cart-table">

          {

            products.map((cartItem, index) => {

              return (
                <div class="item" key={index}>
                  <div className="pro_box">
                    <div className="pro_img">
                      <img src={cartItem.img} className='img_type_3' />
                    </div>

                    <div className="cart-item-details">
                      <div className="title">
                        {cartItem.title}
                        {cartItem.category_name}
                      </div>
                      <div className="price">${cartItem.quantity * cartItem.price}</div>
                      <div class="if-message">Space Reserved for item/promo related messaging</div>
                      <span class="if-status">In Stock</span>
                      <div className="remove">X <span class="blue-link" onClick={() => remove_from_cart(cartItem, index)}>Remove</span></div>
                    </div>
                  </div>

                  <div className="quantity-box">
                    <input type="text" value={cartItem.quantity} class="qty" />
                  </div>

                  <div className="price-right text-right">
                    <strong><span>x</span> ${cartItem.price}</strong>
                  </div>
                </div>
              )
            })
          }
          {/* <!-- end simple product --> */}


          {/* <!-- end bundle product --> */}
    
      </div>  
      <div className="continue">        
          <NavLink class="btn_new left-animated  btn-blue" to="/"> Continue Shopping </NavLink>
        </div>     
      </div>




      <div className="cart_right shadow_bg f_r">
        <div className="item-row">
          <div className="item-total">
            <strong>Item(s) Total</strong>
          </div>

          <div className="total-amount">
            <strong><span class="sb-value">${total}</span></strong>
          </div>
        </div>

        <div className="item-row">
          <div className="item-total">
            Approx. Shipping Charge
              </div>

          <div className="total-amount">
            <strong><span class="sb-value">${shipping}</span></strong>
          </div>
        </div>

        <div className="item-row amount-pay">
          <div className="item-total">
            <strong>Amount Payable</strong>
          </div>

          <div className="total-amount">
            <div class="tax-calculate hide"><input type="text" value="06484" class="tax" /><span class="btn">Calculate</span></div>
            <div class="grand-total"><strong class="sb-value">${total + shipping}</strong></div>
          </div>
        </div>
        <div className="price-change">Prices may change based on shipping address</div>





        <div class="cart-footer">
          {/* <span class="btn">Checkout</span> */}
          <div className="buttons">
            {/* <Checkout total={props.cart_total} /> */}
            <button onClick={process_test_pay}> Test pay </button>

          </div>



          <PayPalButton
            amount="0.01"
            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={(details, data) => {
              alert("Transaction completed by " + details.payer.name.given_name);

              // OPTIONAL: Call your server to save the transaction
              return fetch("/paypal-transaction-complete", {
                method: "post",
                body: JSON.stringify({
                  orderId: data.orderID
                })
              });
            }}
            options={{
              clientId: "ARkXkogJFirUjKpO3l1gocc9iU9LKMPqMnLEpCLN0BIBQELnzvDRv0Niv-ESJTpV85pdM4yxt72xYNdy"
            }}
          />

        </div>
      </div>



    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer.cart,
    cart_total: state.cartReducer.cart_total,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    update_total: (total) => dispatch({ type: "UPDATE_TOTAL", payload: { total: total } }),
    remove_from_cart: (productId) =>
      dispatch({ type: "REMOVE_FROM_CART", payload: { productId: productId } }),
    add_cart_length: (cart_length) =>
      dispatch({ type: "ADD_CART_LENGTH", payload: { cart_length: cart_length } }),
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(Cart);
export default Cart;