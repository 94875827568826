import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { functions } from "../../functions";
import { useSelector } from "react-redux";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//const ENDPOINT = "http://34.65.145.227:3002";
const ENDPOINT = 'https://dev.propennyauction.com:3002';

export const Auction_View = () => {
  const userData = useSelector((userData) => userData);
  const { auction_id } = useParams();
  const [bidMsg, setbidMsg] = useState("");
  const [Auction_data, setAuction_data] = useState({});
  const [in_watchlist, setin_watchlist] = useState(false);
  const [AuctionImages, setAuctionImages] = useState([]);

  const timer = useRef();

   useEffect(() => {
    axios
      .get(
        `http://dev.propennyauction.com/api/auction_detail.php?id=${auction_id}`
      )
      .then((res) => {
        if (res.status == 200 && res.data.success) {
          setAuction_data(res.data.data);
          setin_watchlist(res.data.data.in_watchlist);
          setAuctionImages(res.data.data.prd_img);

          timer.current = setInterval(() => {
            setAuction_data((ad) => {
              return { ...ad, second_left: ad.second_left - 1 };
            });
          }, 1000);
        }
        const socket = window.io.connect(ENDPOINT);
        socket.on("connect", (data) => {
          console.log("socket connected");
        });

        socket.on("push", (data) => {
          push_data(data);
        });

        socket.on("error", (data) => {
          if (!socket.connected) {
            console.log("NOT CONNECTED node ");
          }
        });
        if (!socket.connected) {
          console.log("NOT CONNECTED node ");
        }
      });

    functions.hide_dropdown(1);

    return () => {
      if (timer.current) {
        clearInterval(timer);
      }
    };
  }, []);
  const save_price = Auction_data.rrp - Auction_data.price;
  const save_per =
    100 - ((Auction_data.price / Auction_data.rrp) * 100).toFixed(2);

  // tab-link
  function btm_tabs(e) {
    var all_tabs_ttl = document.querySelectorAll(".tab-link");
    for (const tab_link of all_tabs_ttl) {
      tab_link.classList.remove("current");
    }
    var all_tabs = document.querySelectorAll(".tab-content");
    for (const all_tab of all_tabs) {
      all_tab.style.display = "none";
      all_tab.classList.remove("current");
    }
    e.target.classList.add("current");
    document.getElementById(e.target.getAttribute("dataTab")).style.display =
      "block";
  }

  function secondsToTime(secs, cs) {
    if (cs === 1 || cs == 1 || cs == "1") {
      return "SOLD";
    }
    if (secs < 0 && cs !== 1) {
      return "00:00:00 ";
    }
    let hours = Math.floor(secs / (60 * 60));
    if (hours < 10) hours = "0" + hours;

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    if (minutes < 10) minutes = "0" + minutes;

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if (seconds < 10) seconds = "0" + seconds;

    let obj = hours + ":" + minutes + ":" + seconds;
    return obj;
  }

  function push_data(data) {
    if (data.id === auction_id) {
      setAuction_data((ad) => {
        let newBidd = [
          { bid_val: data.p, username: data.u, description: "Auto bid" },
        ];
        let addBidd = ad.bids;
        let allBids = [...newBidd, ...addBidd];
        let showBids = allBids.slice(0, 10);
        return {
          ...ad,
          second_left: data.s,
          price: data.p,
          cs: data.cs,
          username_leader: data.u,
          bids: showBids,
        };
      });
    }
  }

  const placeBid = (e) => {
    let id = Auction_data.id;
    let user_id = userData.login.user_id;
    let bid_val = Auction_data.price;
    setbidMsg("Placing Bid..");
    let postData = { id, user_id, bid_val };
    axios
      .post(
        "http://dev.propennyauction.com/bid_ng.php",
        JSON.stringify(postData)
      )
      .then((response) => {
        setbidMsg(response.data.auction.Auction.message);
      });

    setTimeout(() => {
      setbidMsg();
    }, 2000);
  };

  return (
    <>
      <div id="container" className="auc-det">
        <div className="step_titel">
          <div className="doc_width">
            <h1>{Auction_data.title}</h1>
            <h1></h1>
          </div>
        </div>
        <div className="lot_id">
          <div className="doc_width">Lot # {Auction_data.id} </div>
        </div>

        <div className="main_content auc_details">
          <div className="doc_width  shadow_bg_des mt-0">
            <div id="auction-details">
              <div className="col1">
                <div className="content">
                  <Carousel
                    isAutoPlaying={true}
                    images={AuctionImages}
					autoPlayInterval={2000}
                    style={{ background: "#1d1b1b3b" }}
                  />
                </div>
              </div>

              <div className="col2_col3">
                <div className="col2">
                  <div
                    className="content auction-item middlebox auction_11681"
                    rel="auction_11681"
                  >
                    <div className="content_manage_hieght">
                      <div className="padd-15-all">
                        <div
                          className="auc_row"
                          style={{
                            width: "45%",
                            marginRight: "5%",
                            textAlign: "center",
                          }}
                        >
                          <div className="label2">
                            <strong className="time_left">Time Left: </strong>
                          </div>
                          <div className="timer">
                            <div id="timer_11681" className="timer countdown">
                              {secondsToTime(
                                Auction_data.second_left,
                                Auction_data.cs
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="auc_row">
                          <div className="timer">
                            <div className="label2 top_auc_price">
                              <strong>
                                <span className="up_price">Current price:</span>{" "}
                                <br />
                                <font className="bid-price">
                                  {/* {functions.currency(Auction_data.price)} */}
                                  ${Auction_data.price}
                                </font>
                              </strong>{" "}
                              <font className="usd">USD</font>{" "}
                            </div>
                            <div className="buynoprice">
                              <strong>
                                {functions.currency(Auction_data.rrp)}
                              </strong>

                              <p>
                                Save over , {functions.currency(save_price)}({" "}
                                {save_per} % )
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="auc_row"
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          <div className="biddername">
                            <font className="u_name u_name"></font>
                            <font className="bid-bidder">
                              {Auction_data.username_leader}
                            </font>
                          </div>
                        </div>

                        <div
                          className="auc_row bid-msg"
                          style={{
                            width: "100%",
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div className="bid-now">
                            <div className="bid-button">
                              <button
                                className="b-login-new"
                                onClick={placeBid}
                              >
                                Bid Now
                              </button>
                            </div>
                          </div>
                          <p>{bidMsg}</p>
                        </div>

                        <div className="add_business">
                          {!Auction_data.in_watchlist ? (
                            <p
                              className="star_icon"
                              style={{ cursor: "pointer" }}
                            >
                              Add to Watchlist
                            </p>
                          ) : (
                            <p
                              className="star_icon"
                              style={{ cursor: "pointer" }}
                            >
                              Remove from Watchlist
                            </p>
                          )}

                          <p>
                            Followed by <strong>613</strong> people{" "}
                            <font className="orange_text">
                              (added into watchlist)
                            </font>
                          </p>

                          <p>
                            With each bid, the auction time reset to{" "}
                            <strong>20s</strong>
                          </p>
                          <p>Highest bidder wins!</p>
                        </div>

                        <p className="err"></p>
                        <span className="vat"></span>
                      </div>

                      <div className="view_inquiry">
                        <span className="f_l">
                          Price Increment: <strong>$0.01</strong>
                        </span>

                        <span className="f_r">
                          BuyNow Price <strong>$586.00</strong>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="informations">
                    <div className="padd-15-all">
                      <li>
                        <span>Normal price </span>
                        <p>$586.00</p>
                      </li>

                      <li>
                        <span>Auction Id </span>
                        <p>11681</p>
                      </li>

                      <li>
                        <span>Start time </span>
                        <p>2020-08-25 02:08:43</p>
                      </li>

                      <li>
                        <span>End time </span>
                        <p>2020-08-25 02:27:50</p>
                      </li>

                      <li>
                        <span>Payments </span>
                        <p>
                          <img src="images/paymant-opstion.gif" alt="" />
                        </p>
                      </li>

                      <li>
                        <span>Price Start </span>
                        <p>{functions.currency(Auction_data.start_price)}</p>
                      </li>

                      <li>
                        <span>Shipping Fee </span>
                        <p>{functions.currency(Auction_data.delivery_cost)}</p>
                      </li>
                    </div>
                  </div>
                </div>

                <div className="col3_outer">
                  <div className="col3">
                    <div className="panes">
                      <div id="tabs">
                        <ul className="tabs">
                          <li id="bid-h">Bid Histories</li>
                        </ul>
                        <div
                          id="fragment-1"
                          className="shadow_bg shadow_bg_history"
                        >
                          <div
                            className="bid-history bid-histories"
                            id="bidHistoryTableauction_11681"
                            style={{ display: "block" }}
                            title="11681"
                          >
                            {" "}
                            <div className="overflow">
                              <table
                                width="100%"
                                cellSpacing="0"
                                cellPadding="0"
                                border="0"
                              >
                                <thead>
                                  <tr
                                    style={{
                                      backgroundColor: "rgb(255, 255, 255)",
                                    }}
                                  >
                                    <th>Username</th>
                                    <th>Price</th>
                                    <th>Bid Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Auction_data.bids &&
                                    Auction_data.bids.map((bids, i) => {
                                      let css = {};
                                      if (i % 2 == 0) {
                                        css.backgroundColor =
                                          "rgb(240, 248, 255)";
                                      }
                                      return (
                                        <React.Fragment key={i}>
                                          <tr style={css}>
                                            <td>{bids.username}</td>
                                            <td>{bids.bid_val}</td>
                                            <td>{bids.description}</td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ position: "relative" }}>
                        <div
                          className="bidbutler shadow_bg shadow_bg_bidbuddy"
                          style={{ position: "relative" }}
                        >
                          <ul className="tabs">
                            <li>Bid Butler</li>
                          </ul>

                          <div className="bidbuddy_new">
                            <p className="bidbuddy_text">
                              BidBuddy monitors your auctions when you cannot be
                              on line, or when you would like to have bids
                              placed automatically for you.{" "}
                            </p>

                            <label>Number of Bids</label>
                            <input
                              type="textbox"
                              className="set_input"
                              name="bulter_bids"
                            />

                            <div className="submit">
                              <input
                                type="submit"
                                className="btn"
                                name="Submit"
                                id="Submit"
                                value="Add Butler"
                                tabIndex="4"
                              />
                            </div>

                            <input type="hidden" name="aid" value="11681" />
                            <input type="hidden" name="Submit_x" value="1" />
                            <input type="hidden" name="Submit_y" value="1" />
                          </div>
                        </div>

                        <div className="view_closed"></div>
                      </div>

                      <div style={{ clear: "both" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }}></div>

        <div className="doc_width hide">
          <div className="but_itnow" style={{ display: "none!important" }}>
            <br />
            <a href="payment.php?m=YnV5X25vdw==&amp;i=MTE2ODE=">Buy Now</a>
            <br />
          </div>

          <div
            className="tab_con"
            style={{ overflow: "hidden", background: "#fff" }}
          >
            <div className="padd-15">
              <ul className="tabs_s">
                <li
                  className="tab-link left current"
                  dataTab="tab-1"
                  onClick={(e) => btm_tabs(e)}
                >
                  Description
                </li>
                <li
                  className="tab-link center_line"
                  dataTab="tab-2"
                  onClick={(e) => btm_tabs(e)}
                >
                  Shipping Information
                </li>
                <li
                  className="tab-link center_line"
                  dataTab="tab-3"
                  onClick={(e) => btm_tabs(e)}
                >
                  Latest Prices
                </li>
                <li
                  className="tab-link center_line"
                  dataTab="tab-4"
                  onClick={(e) => btm_tabs(e)}
                >
                  Help
                </li>
                <li
                  className="tab-link right"
                  dataTab="tab-5"
                  onClick={(e) => btm_tabs(e)}
                >
                  Auction Information
                </li>
              </ul>

              <div id="tab-1" className="tab-content current">
                {Auction_data.description}
              </div>

              <div id="tab-2" className="tab-content">
                <h1>shipping details </h1>
              </div>

              <div id="tab-3" className="tab-content">
                <h1>
                  Latest Prices: <strong className="bid-price">$2.24</strong>{" "}
                </h1>
              </div>

              <div id="tab-4" className="tab-content">
                <h1>Help</h1>
              </div>

              <div
                id="tab-5"
                className="tab-content center_content tab_auction_info"
              >
                <h1>Auction Info</h1>
                <div className="row">
                  <p>
                    Auction id: <strong>11681</strong>
                  </p>
                  <p>
                    Auction Type:
                    <strong>Penny Auction</strong>
                  </p>
                  <p>
                    Auction Price: <strong className="bid-price">$2.24</strong>
                  </p>
                </div>

                <div className="row">
                  <p>
                    Start Price: <strong>$1.00</strong>
                  </p>
                  <p>
                    Price Increment: +<strong>$0.01</strong>{" "}
                  </p>
                  <p>
                    Shipping Fee: <strong>$0.00</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
