import React, { useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

function About(props) {

    window.scrollTo(0, 0)
    const [progress, setProgress] = useState(100);

    return (
        <>
        
        <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

        <div className="doc_width shadow_bg inner_page">
        <div className="step_titel">
            <h1>Contact Us</h1>
        </div>
                        
        <div id="rightcol" className="package_box package_page">
        
        <div className="page_list">
        
   
        <p>
        Online Auction (Bidding) Software
Gain access to the best solutions the auction industry has to offer.
It doesn’t matter what type of auctions you are managing, because Propenny is capable of offering suitable solutions for
 a variety of industries. Due to extensive experience in this domain, we know what works and what doesn’t across the
  entire auction industry. You can use our auction software as-is or we can customize further to meet you specific
   business requirements. so we can be the reliable partner you need. </p>

   <p>At the moment, our team is the most trusted and with the biggest number of members, so you can be sure that with us you will find the answers you are seeking. Using the top-notch technology, Node.Js and Socket.io, Redis your auctions will be continuously updated in real time.</p>
   
  
  
   <p>Offer your clients modern and professional-looking car auctions with the help of our software solutions. With support available around the clock and without the hassle of negotiations, the Propenny software if the best way to start a profitable business in this domain.</p>  
      
      
      <p>Your business needs tailored solutions? Then don't hesitate to contact us. The Propenny team is ready to answer to all the requirements of professional auctioneers, creating customized websites and mobile apps to meet the specific needs of your auction business.</p>  


        </div> 
                       
        </div>
        </div> 
        
        </>
    );
}

export default About;