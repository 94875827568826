import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom';

export const Footer = () =>{
  return (
 <Fragment>   
  
 
 
 

 
<div className="footerbg">

<div className="fot_invite">
<div className="doc_width">
<span><strong>Invite Your Friends</strong> and get free bids.</span>
<a className="btn_new left-animated  btn-blue white-outline-btn" href="/invite-friend">
Invite Friends</a>
</div>
</div>


<div className="doc_width">

<div className="footerlink">


<ul className="about_ul">
<p className="logo_footer"><img src="/images/logo_fot.png" /></p>
<p className="about_p">
Welcome to PropennyAuction Demo - Home of Latest bidding fee auctions. 
We offer a wide variety of Mobiles,Computers,Fasion, Books and Cars,
 as well as individual cards.
</p>
</ul>



<ul>
<h2>Quick Links</h2>
  <li><NavLink to="/signup">Register now</NavLink></li>								
 <li><NavLink to="/home/Live">Live Auctions</NavLink></li>								
 <li><NavLink to="/home/Closed">Clsoed Auctions</NavLink></li>								
<li><NavLink to="/packages">Buy Bids</NavLink></li>
  <li><NavLink to="/store">Store</NavLink></li>
  <li><NavLink to="/cart">Cart</NavLink></li>
  <li><NavLink to="/how_it_works">Help</NavLink></li>	
</ul>


  	 
    
    	<ul>
        <h2>About US</h2>
        <li><NavLink to="/about">About</NavLink></li>	
        <li><NavLink to="/privacy">Privacy</NavLink></li>	
        <li><NavLink to="/terms">Terms</NavLink></li>	
		
   	   </ul>
    
	
    	<ul>
        <h2>Support</h2>
        <li><NavLink to="/how_it_works">How it works</NavLink></li>	
        <li><NavLink to="/penny_auction_guide">Penny Auction Guide</NavLink></li>	
        <li><NavLink to="/seat_auction_guide">Seat Auction Guide</NavLink></li>	
        <li><NavLink to="/unique_auction_guide">Unique Auction Guide</NavLink></li>	
        <li><NavLink to="/ebay_auction_guide">Ebay Auction Guide</NavLink></li>	
        <li><NavLink to="/contact">Codntact</NavLink></li>	
        <li><NavLink to="/contact1">Codntact</NavLink></li>	
    
 		</ul>
        
    	<ul className="follow">
        <h2>follow us on:</h2>
		<li className="social_icon f_icon"><a href="#">&nbsp;</a></li>
<li className="social_icon t_icon"><a href="#">&nbsp;</a></li>
<li className="social_icon y_icon"><a href="#">&nbsp;</a></li>
<li className="social_icon g_icon"><a href="#">&nbsp;</a></li>		
		</ul> 
		
        
</div>
   
</div>
</div>
  
</Fragment>
  )
}


