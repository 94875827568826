import React, { Fragment, useEffect, useState } from "react";
import { UserMenu } from "../../elements/user_menu";
import axios from "axios";
import { useSelector } from "react-redux";
import { functions } from "../../functions";
import ReactPaginate from "react-paginate";
import LoadingBar from "react-top-loading-bar";

export const Bid_buttler = () => {
  const [auctions, setAuctions] = useState([]);
   const [auctionPerPage, setAuctionPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [flag, setFlag] = useState(false);
  const Data = useSelector((data) => data);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(20)
    PageData();
    setProgress(90);
    setProgress(100);
  }, [auctionPerPage, currentPage]);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
  };

  const DeleteAuction = async (aid, index) => {
    let req = await axios.post(
      `http://dev.propennyauction.com/api/bid_butler.php?user_id=${window.localStorage.getItem(
        "user_id"
      )}&del_id=${aid}`
    );
    let res = await req;

    let duplicateAuctions = [...auctions];
    duplicateAuctions.splice(index, 1);
    setAuctions(duplicateAuctions);
    functions.flash_msg(
      '<div class="center_popup">Records Deleted Successfully</div>'
    );
  };

  const PageData = async () => {
    let req = await axios.get(
      "https://dev.propennyauction.com/api/bid_butler.php?user_id=" +
        window.localStorage.getItem("user_id") +
        "&page=" +
        currentPage +
        "&rowsPerPage=" +
        auctionPerPage
    );
    let res = await req;
    setAuctions(res.data.data);
    setTotalPage(res.data.maxPage);
    if (res.data.data) {
      setFlag(true);
    }
  };
  const handleActionPerPage = () => {
    const element = document.getElementById("auctionPerPage");
    const value = element.options[element.selectedIndex].value;
    setAuctionPerPage(parseInt(value));
    setCurrentPage(1)
  };

  return (
    <>
     
      <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

      <UserMenu />

      <div className="doc_width shadow_bg">
        <div className="step_titel">
          <h1>My Bid Butlers</h1>
        </div>
        <div className="table-responsive">
          <div id="rightcol">
            <table
              className="results"
              width="100%"
              cellSpacing="0"
              cellPadding="0"
              border="0"
            >
              <tbody>
                <tr>
                  <th> ID</th>
                  <th> Auction ID</th>
                  <th> Title </th>
                  <th> Bids Left </th>
                  <th> Actions </th>
                </tr>

                {flag ? (
                  auctions.map((auction, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="normal_bg padd" colSpan="">
                            {auction.id}
                          </td>
                          <td className="normal_bg padd" colSpan="">
                            {auction.auction_id}
                          </td>
                          <td className="normal_bg padd" colSpan="">
                            {auction.title}
                          </td>
                          <td className="normal_bg padd" colSpan="">
                            {auction.bids}
                          </td>
                          <td
                            className="normal_bg padd buttons buttons-del "
                            colSpan=""
                          >
                            <button
                              onClick={() => DeleteAuction(auction.id, i)}
                            >
                              DELETE
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <span>Please log in to view the data...</span>
                )}
              </tbody>
            </table>
            <table width="100%" cellSpacing="10" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td align="center" className="pagination">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      pageCount={totalPage}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"page-active"}
                    />
                    <div className="mt-11">
                      <label for="auctionPerPage" className="ml-5">
                        Auctions per page :
                      </label>

                      <select
                        id="auctionPerPage"
                        className="select-item "
                        onClick={handleActionPerPage}
                      >
                        <option value="5" selected>
                          5
                        </option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
