import React, { Fragment, useEffect, useState } from 'react';
import { UserMenu } from '../../elements/user_menu';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { functions } from '../../functions';
import LoadingBar from "react-top-loading-bar";

export const Accounts = () => {

  const [accountData, setaccountData] = useState([]);
  const Data = useSelector((data) => data);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(30);
    PageData();
    functions.hide_dropdown(1);
    setProgress(100);
  }, []);

  const PageData = async () => {
    let req = await axios.get(" http://dev.propennyauction.com/api/accounts.php?user_id=" + window.localStorage.getItem("user_id"));
    let res = await req;
    if (res.data.success) {
      setaccountData(res.data.data);
    }
  }
  return (
    <Fragment>
      <LoadingBar color="#FF0000" progress={progress} height={2} onLoaderFinished={() => setProgress(0)} />


      <UserMenu />

      <div className="doc_width shadow_bg">
        <div className="step_titel">
          <h1>My Accounts</h1>
        </div>
        <div className="table-responsive">
          <div id="rightcol">
            <table className="results" width="100%" cellSpacing="0" cellPadding="0" border="0">
              <tbody><tr>
                <th> ID </th>
                <th> Auction ID</th>
                <th> description </th>
                <th> price </th>
                <th> created </th>

              </tr>
                {

                  accountData.map((account, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="normal_bg padd" colSpan="">{account.id}</td>
                          <td className="normal_bg padd" colSpan="">{account.auction_id} </td>
                          <td className="normal_bg padd" colSpan="">{account.description} </td>
                          <td className="normal_bg padd" colSpan="">{functions.currency(account.price)} </td>
                          <td className="normal_bg padd" colSpan="">{account.created} </td>
                        </tr>


                      </React.Fragment>
                    )
                  })
                }

              </tbody></table>
            <table width="100%" cellSpacing="10" cellPadding="0" border="0">
              <tbody><tr><td align="center"></td>
              </tr></tbody></table>

          </div>
        </div>



      </div>
    </Fragment>
  );
}