import React, { useState, useEffect,   } from 'react';
 import axios from 'axios';
import { UserMenu } from '../../elements/user_menu'
import { useSelector } from 'react-redux';
import { AddressIndexInclude } from './address_index_include';
import { EditAddressInclude } from './edit_address';
import {functions} from  '../../functions';
import LoadingBar from "react-top-loading-bar";


export const Address = () => {
 // /address/edit/billing
 
 // update addtss:   address_add_edit.php?user_id=55

const userData = useSelector( (userData)=>{
  return userData 
})

const [ userShipping, setUserShipping ] = useState({});
const [ userBilling, setUserBilling ] = useState({});
const [ EditAddress, setEditAddress ] = useState(false);
const [ address_type, set_address_type ] = useState('');
const [ progress, setProgress ] = useState(100);

useEffect(async() => {
  const cancelTokenSource = axios.CancelToken.source();


  let req = await axios.get("http://dev.propennyauction.com/api/addresses.php?user_id=" + userData.login.user_id, {
                      cancelToken: cancelTokenSource.token
  } );
  let res = await req;  
  if(res.data){
    setUserShipping(res.data.data.shipping); 
    setUserBilling(res.data.data.billing); 
   }

   functions.hide_dropdown(1); 


   return ()=>{ cancelTokenSource.cancel();   } // memory leck error fix

   
   setProgress(100);

},[EditAddress && EditAddress]);


const setEditProfile = (address_type) =>{
  setEditAddress(true);
  set_address_type(address_type);
}



return(
<>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<UserMenu />  
<div className="step_titel">
<div className="doc_width">
<h1>My Addresses    </h1>
 
     
</div>
</div>


 
 {
 ( 
() => {
if(!EditAddress){      
 return <AddressIndexInclude userShipping={userShipping} userBilling={userBilling} setEditProfile={setEditProfile}/>
}else{
  return  <EditAddressInclude userShipping={userShipping} userBilling={userBilling} setEditAddress={setEditAddress} userData={userData} address_type={address_type != '' &&  address_type}  />
}
} 
 )() 
}

</>
) 
}