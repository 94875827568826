
export let functions = {
    currency: (amount, decimalCount = 2, decimal = ".", thousands = ",") => {


        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "$";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }


    },

    flash_msg: (str) => {

        try {
            document.getElementById('flash_msg').innerHTML  = str;
            document.getElementById('flash_msg').style.display = 'block';
            setTimeout(() => {
                document.getElementById('flash_msg').style.display = 'none';
              },2000);
        } catch (e) {
            console.log(e)
        }

    },
    hide_dropdown: (str) => {

        try {
            var testarray = document.getElementsByClassName("slide_mayaccount");
            for(var i = 0; i < testarray.length; i++) {
                testarray[i].classList.remove('menu-show')
            }
            
        } catch (e) {
            console.log(e)
        }

    }



}