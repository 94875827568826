import React, { Fragment, useEffect, useState } from 'react';
import { UserMenu } from '../../elements/user_menu';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { functions } from '../../functions';
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingBar from "react-top-loading-bar";


export const Watch_list = () => {

  const [auctions, setAuctions] = useState([]);
  const [loading, setloading] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [pageNum, setpageNum] = useState(1);
  const Data = useSelector((data) => data);
  const [progress, setProgress] = useState(100);

 
  useEffect(() => {
    setProgress(30);
    //PageData();
    fetch_next();
    setProgress(100);

  }, []);

  const DeleteAuction = async (aid, index) => {
     let req = await axios.post(`http://dev.propennyauction.com/api/watchlist_add-del.php?action=remove-watchlist&user_id=${window.localStorage.getItem("user_id")}&auction_id=${aid}`)
     let res = await req;
     let duplicateAuctions = [...auctions];
    duplicateAuctions.splice(index, 1);
    setAuctions(duplicateAuctions);
    functions.flash_msg('<div class="center_popup">Records Deleted Successfully</div>');

  }

  const PageData =  () => {
      axios.get("http://dev.propennyauction.com/api/watchlist.php?page="+ pageNum +"&user_id=" + window.localStorage.getItem("user_id"))
     .then((res)=>{
        if (res.data.success) {
          setAuctions(res.data.data);
        }
     }).catch((e)=>{
        console.log('error', e)
    })
     
    
  }

  const fetch_next = () => {

    setloading(true)
     axios.get("http://dev.propennyauction.com/api/watchlist.php?page="+pageNum+"&user_id=" + window.localStorage.getItem("user_id"))
        .then((res)=>{
          if (res.data.success) {
           let new_data = res.data.data 
           let all_data = auctions
           let all_merge = [ ...all_data, ...new_data ]
            setAuctions( all_merge);
            setpageNum(pageNum+1)
            setloading(false)
            sethasMore(res.data.hasMore)
            }
        }).catch((e)=>{
            console.log('error', e)
        })
     
   
   }
   return (
    <Fragment>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

      <UserMenu />
      <div className="doc_width shadow_bg">
        <div className="step_titel mar_b_0">
          <h1>Watch List</h1>
        </div>
        <div className="table-responsive">
          <div id="rightcol">
           
                 
                   <InfiniteScroll
                   dataLength={auctions.length}
                   next={()=>fetch_next()}
                   hasMore={hasMore}
                   >
                     <table className="results" width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>   
                        <tr>
                          <th> Image</th>
                          <th> id</th>
                          <th> auction id</th>
                          <th> Title</th>
                          <th> End Time </th>
                          <th> Price </th>
                          <th> Actions </th>
                        </tr> 
                       { 
                        auctions.map( (auction, index)=>(
                            
                             <tr key={auction.id}>
                                <td className="normal_bg padd" colSpan=""><img src={`${auction.bids}`} /></td>
                                <td className="normal_bg padd" colSpan="">{auction.id} </td>
                                <td className="normal_bg padd" colSpan="">{auction.auction_id} </td>
                                <td className="normal_bg padd" colSpan="">{auction.title} </td>
                                <td className="normal_bg padd" colSpan="">{auction.end_time} </td>
                                <td className="normal_bg padd" colSpan="">{auction.price} </td>
                                <td className="normal_bg padd buttons buttons-del" colSpan="">
                                  <button onClick={() => DeleteAuction(auction.auction_id, index)}>DELETE</button>
                                </td>
                              </tr>
                            
                        ))
                      }
                      
                      </tbody></table>
                      
                       </InfiniteScroll>    
                
 


              
            <table width="100%" cellSpacing="10" cellPadding="0" border="0">
              <tbody>
                <tr><td align="center">{ (loading) ? 'Loading more..': ''}</td></tr>
                <tr><td align="center">Showing Page: {pageNum}</td></tr>
                
                </tbody></table>

          </div>
        </div>



      </div>
    </Fragment>
  )
}