import React, {useState, Fragment } from 'react'
import { NavLink } from 'react-router-dom';

	

export const UserMenu = () =>{

	const [isActiveHumgerU, setrctToggleHumgerU] = useState(false);
	const rctToggleHumgerU = (event) => {
		setrctToggleHumgerU(!isActiveHumgerU);
		}

  return (
 <Fragment>   
  
 
    
<div className="user_menu mar_b_20"> 
          <div className="doc_width padd_t_10 padd_b_10">
<a onClick={rctToggleHumgerU} className={isActiveHumgerU ? 'left-nav-btn left-nav-btn-close': 'left-nav-btn'} href="javascript:void(0)"></a>
<div id="leftuser" className={isActiveHumgerU ? 'leftblock_inner slide_login_user  add_ani': 'leftblock_inner slide_login_user closed add_ani'}>
	<ul>  
	<li><NavLink activeClassName="act"  to="/users">Dash Board</NavLink></li>
	<li><NavLink activeClassName="act"  to="/edit_profile">Edit Profile</NavLink></li>
	<li><NavLink activeClassName="act"  to="/change_password">Change Password</NavLink></li>
	<li><NavLink activeClassName="act"  to="/watch_list">My  Watchlist</NavLink></li>
	<li><NavLink activeClassName="act"  to="/address">My Addresses</NavLink></li>
	<li><NavLink activeClassName="act"  to="/packages">Purchase Bids</NavLink></li>		
	<li><NavLink activeClassName="act"  to="/bid_butlers">My Bid Butlers</NavLink></li>
	<li><NavLink activeClassName="act"  to="/won_auctions">	Won Auctions</NavLink></li>
	<li><NavLink activeClassName="act"  to="/my_bids">My Bids</NavLink></li>
	<li><NavLink activeClassName="act"  to="/accounts">My Account</NavLink></li>		
	<li><NavLink activeClassName="act"  to="/invite-friend">Invite My Friends	</NavLink></li>    
	</ul>    
     </div>        
 </div>
        </div>	  
	  
  
</Fragment>
  )
}


