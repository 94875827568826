import React, { useEffect } from 'react'
import { Redirect,NavLink, useHistory } from 'react-router-dom';

import  './logincss.css';

export const Signup = () =>{
let  history = useHistory()

  useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, []);
	
	const escFunction =  (event) => { 
		if (event.keyCode === 27) { console.log('jj escFunction: ' + event.keyCode )
			return (
				history.push('/') // <Redirect to="/" /> not worked
			)
		}
	}


  return (
    
 <React.Fragment>
	
	 
<div id="container" class="user-container user-container-login">

				    
<div id="login" class="outline_btn register input_line">
<div class="centre_box centre_box_register login_box fadeInDown animated">	 
  <div class="login_box_right">
  <NavLink to="/"><span className="close-btn">X</span></NavLink>
  <div class="login-only padd_40">

	<div className="text-center"> 
		<img className="logo_inner" src="images/logo.png" />

		<div className="dont_have_text">
		<p className="mar_0"><strong>I'M NEW HERE</strong></p>
		<div class="info width_80 center_div text-center text-light padd_b_20">If you don't have an account you need to register first before you start bidding.</div>
		</div>
	</div>

  
  <div class="signup_row">
  <div class="right_signup">

<p class="err"></p>

<div class="register_manage_height">

	  
<div class="row">	
<div class="edit_user">
<div class="input text">
<input  type="text" type="username" name="first_name" defaultValue="First Name" />
</div>
		
</div>
	
	
<div class="edit_user">
<div class="input text">
<input  type="text" name="username" defaultValue="Username" /></div>
		
</div>		  
</div>


 <div class="row">	
<div class="edit_user">
<div class="input text">
<input  type="text" name="mobile" defaultValue="Phone" /></div>
		
</div>
	
	
<div class="edit_user">
<div class="input text">
<input  type="text" name="email" defaultValue="Email" /></div>
		
</div>		  
</div>


<div class="row">	
<div class="edit_user">
<div class="input text">
<input  type="text" name="last_name" defaultValue="Last Name" /></div>
		
</div>
	
	
<div class="edit_user">
<div class="input text">
<input  type="password" name="password" defaultValue="Password" /></div>
</div>		  
</div>



<div class="submit" style={{clear: 'both',float: 'left',width: '100%'}}>
<input type="submit" name="submit" id="submit" value="Sign Up" />
</div>


<div class="forgotpassword padd_t_30 text-left">
	<div class="checkbox">Already a Member? Please Login</div>
	<NavLink className="btn_new left-animated  btn-blue small_btn" to="/login">Login Now</NavLink>
	</div>


	  
</div>



</div>
</div>
</div>
</div>

  
  
  
  


</div>      
</div>


</div>
 

</React.Fragment> 

  )
}

