import React, { useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

export const Seat_auction_guide = () => {
     window.scrollTo(0, 20)
     const [progress, setProgress] = useState(100);

return(
<>

<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<div className="doc_width shadow_bg inner_page">
<div className="step_titel">
	<h1>Seat Auction Guide</h1>
</div>                
<div id="rightcol">

<p>this is how it seat auction guide page  </p>
<p>this is how it seat auction guide works page </p>



               
</div>
</div> 

</>
);	

}