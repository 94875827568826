import React, { Fragment, useRef,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Redirect, NavLink,useHistory  } from 'react-router-dom';

import './logincss.css';

export const Login = () => {
	let history = useHistory();

	const userData = useSelector(userData => userData);
	const dispatch = useDispatch();
	const usernameRef = useRef();
	const passwordRef = useRef();

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, []);
	
	const escFunction =  (event) => { 
		
		if (event.keyCode === 27) { console.log('jj escFunction: ' + event.keyCode )
			return (
				history.push('/') // <Redirect to="/" /> not worked
			)
		}
	}

	const HandleLoginEvent = (event) => {
		let username = usernameRef.current.value;
		let password = passwordRef.current.value;
		if (username !== "" && password !== "") {
			let req = axios.post("http://dev.propennyauction.com/api/login.php", { username, password });
			req.then(res => {
				//console.log(res.data.user);
				if (res.data.user.user_id > 0) {
					//console.log(res.data.user);
					//setting all user information in redux to use them globally
					let SET_USER_ID = 'SET_USER_ID';
					let SET_USERNAME = 'SET_USERNAME';
					let SET_EMAIL = 'SET_EMAIL';
					dispatch({ type: SET_USER_ID, payload: Number(res.data.user.user_id) });
					dispatch({ type: SET_USERNAME, payload: res.data.user.user_username });
					dispatch({ type: SET_EMAIL, payload: res.data.user.user_email });
					//localStorage setting item
					window.localStorage.setItem("user_id", Number(res.data.user.user_id));
					window.localStorage.setItem("username", res.data.user.user_username);
					window.localStorage.setItem("email", res.data.user.user_email);
				}
			}).catch(err => {
				console.log('err:' + err);
			})
		}
	}

	if (Number(userData.login.user_id) > 0) {
		return <Redirect to="/" />
	}

	return (
		<Fragment>

			<div id="container" className="user-container user-container-login">
				<div id="login" className="outline_btn page_login">
					<div className="centre_box login_box fadeInDown animated">

						<div className="login_box_right">
						<NavLink to="/"><span className="close-btn">X</span></NavLink>
							<div className="login-only padd_40">
							<div className="text-center"> 
								<img className="logo_inner" src="images/logo.png" />

								<div className="dont_have_text">
									<span className="dont_have">Not registered yet?</span>
									<NavLink className="btn_new left-animated  btn-blue small_btn" to="/signup">Signup</NavLink>
								</div>
							</div>							

								<div className="row padd_tb_20">
									<div className="form-group">
										<input ref={usernameRef} type="text" maxLength="40" defaultValue="demouser" name="username" />
									</div>

									<div className="form-group">
										<input ref={passwordRef} type="password" id="password" defaultValue="123456" name="password" />
									</div>




									<div className="forgotpassword">
										<div className="checkbox">
											<p>Username:<strong>demouser</strong></p>
											<p>Password:<strong>123456</strong></p><p>
											</p></div>
									</div>


									<div className="submit">
										<input onClick={HandleLoginEvent} type="submit" name="imageField" id="imageField" value="Login" className="send" />
									</div>

									<div className="forgotpassword padd_t_30">
										<div className="checkbox">
										
										 <NavLink className="btn_new left-animated  btn-blue small_btn" to="/reset_pass">Reset Password</NavLink>
									
										</div>

										<NavLink className="btn_new left-animated  btn-blue small_btn" to="/signup">Create Account</NavLink>
									</div>


								</div>


							</div>

						</div>
					</div>
				</div>
			</div>



		</Fragment>
	);

}