import React, { Fragment,  useEffect, useRef, useState  } from 'react';
 import axios from 'axios';
import  './logincss.css';
import { UserMenu } from '../../elements/user_menu'
import { useSelector } from 'react-redux';
import { functions } from '../../functions';
import LoadingBar from "react-top-loading-bar";

// change_password.php
 
export const Change_Password = () => {
  const [progress, setProgress] = useState(100);

  const userData = useSelector( (userData)=>{
    return userData 
 })

 useEffect(() => {
   functions.hide_dropdown(1);  
   setProgress(100);

},[]);

  const old_pass = useRef()  
  const new_pass = useRef()  
  const confirm_pass = useRef()  

  async function postUserdata(){
    console.log('postUserdata function ')
    let old_pass1 =  old_pass.current.value  
    let new_pass1 =  new_pass.current.value  
    let confirm_pass1 =  confirm_pass.current.value  
    
    let postData = { old_pass1,new_pass1,confirm_pass1  }
  
    let req = await axios.post("http://dev.propennyauction.com/api/change_password.php?user_id=" + userData.login.user_id, JSON.stringify(postData) );
    let res = await req;   
   console.log(res);
  
  }



 return(
 <Fragment> 
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<UserMenu />      

<div className="doc_width shadow_bg input_line input_only_line change_password"> 
<div className="step_titel">
  <h1>Change Password</h1>   
</div>
            <div id="rightcol">
      <div className="rightcol_inner">    
       
         <div className="edit_user">
                <div className="input text">
                  <label for="name">Old Password</label>
                  <input type="password" ref={old_pass}  defaultValue="" />  
          
                  </div>                 
                </div>
        
        <div className="edit_user">
                <div className="input text">
                  <label for="name">New Pass</label>
                 <input type="password" ref={new_pass} defaultValue="" />
                        
                  </div>                 
                </div>
        
        <div className="edit_user">
                <div className="input text">
                  <label for="name">Confirm Pass</label>
                    <input type="password" ref={confirm_pass}  defaultValue="" />
                        
                  </div>                 
                </div>
        
     
                     
<br style={{clear:'both'}} />
<br style={{clear:"both"}} />
   <div className="submit">
              <input onClick={postUserdata}  type="submit" name="submit" id="submit" value="Change Password" className="send" style={{cursor:'no-drop'}} />
            </div>
        
          
         
         
            
          
     
    </div>
        </div>
  </div>
</Fragment>
 ); 
}