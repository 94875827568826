import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; 
import { BrowserRouter as Router } from 'react-router-dom';
import { LoginReducer } from './view/userR/login_Reducer';
 
//import { NotificationReducer } from './view/userR/notification';

import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

const Reducers = combineReducers({
login: LoginReducer
// notification: NotificationReducer
});

 
const Store = createStore(Reducers);  
//Store.subscribe(() => console.log(Store.getState()))
 
ReactDOM.render(
<Router>
<Provider store={Store}>
     <App />
</Provider>
</Router>,      
   document.getElementById('root')
);

 
 