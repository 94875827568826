import React, { Fragment, useEffect, useState } from 'react';
import { UserMenu } from '../../elements/user_menu';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { functions } from '../../functions';
import LoadingBar from "react-top-loading-bar";


export const Won_auctions = () => {

  const [auctions, setAuctions] = useState([]);
  const Data = useSelector((data) => data);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(30);
     PageData();
    functions.hide_dropdown(1);  
    setProgress(100);
  }, []);


  const PageData = async () => {
    let req = await axios.get(" http://dev.propennyauction.com/api/won_auction.php?user_id=" + window.localStorage.getItem("user_id")  );
    let res = await req;
    if (res.data.success) {
      setAuctions(res.data.data);
    }
  }


return(
<>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />


<UserMenu /> 


<div className="doc_width shadow_bg">       
<div className="step_titel">
  <h1>Edit profile</h1>     
</div>
		<div className="table-responsive">          
     <div id="rightcol"> 
          <table className="results" width="100%" cellSpacing="0" cellPadding="0" border="0">
            <tbody><tr>
              <th> ID</th>
              <th> Image</th>
              <th> Title  </th>
              <th> Price  </th>
              <th> Date Won </th>
              <th> Status </th>
             </tr>
                        			
            {

auctions.map((auction, i) => {
  return (
    <React.Fragment key={i}>
      <tr>
        <td className="normal_bg padd" colSpan="">{auction.id} </td>
        <td className="normal_bg padd" colSpan=""><img src={`${auction.img}`} /></td>

        <td className="normal_bg padd" colSpan="">{auction.title} </td>
        <td className="normal_bg padd" colSpan="">{ functions.currency(  auction.price ) } </td>
        <td className="normal_bg padd" colSpan="">{ auction.end_time } </td>
        <td className="normal_bg padd" colSpan="">{ auction.status_name } </td>
       </tr>


    </React.Fragment>
  )
})
}
          </tbody></table>
          <table width="100%" cellSpacing="10" cellPadding="0" border="0">
            <tbody><tr><td align="center"></td>         
          </tr></tbody></table>
                    
        </div>
       </div>  
</div>
</>
);  
}