import React, { Fragment, useEffect, useState  } from 'react';
import { UserMenu } from '../../elements/user_menu';
import axios from 'axios';
import {functions} from  '../../functions';
import { NavLink } from 'react-router-dom';


//  /paypal/package/12   button link 

export const User_package = () => {

const [ Packages, setPackages ] = useState([]);

console.log('Packages')
console.log(Packages)


useEffect(async() => {
	let req = await axios.get("http://dev.propennyauction.com/api/packages.php");
	let res = await req;
	if(res.data.success){
		setPackages(res.data.data);
	} 
	functions.hide_dropdown(1);  

},[]);	

return(
<Fragment>
<div className="step_titel">
      <div className="doc_width">
        <h1>Packages</h1>
              
        </div>
      </div>

<UserMenu />

<div className="doc_width shadow_bg">
                
				<div id="rightcol" className="package_box package_page">
				<h2> Which Package Is Right For You?</h2>
				<div className="perfect_text">Perfect For Beginners  </div>
				
					   <ul>
							
					<form action="payment.html" method="post" name="formulario">
{	
Packages.map((packageData,index) => {
return(
<>
                <li key={index}>              	
							<label>{packageData.name}</label>
				   <div className="pack_img"><img src={`images/bids_${packageData.bids}.png`} /></div>
							<span className="hide"><strong>{packageData.bids}</strong> Bids</span>
											 	<p> { functions.currency(packageData.price) }   </p>
 							   <div className="submit"> 						
						
	<NavLink to={`/paypal/packages/${packageData.id} `} className="send" style={{cursor:'pointer', padding: '50px' }}>Buy Bids </NavLink>					
 
						   
						   
						   </div>                                              
							</li> 
</>
)
})							
}
					   </form>  
					   </ul>
				   </div>
			 </div>
 		   
</Fragment>
);	

}