import React, { Fragment, useState, useEffect, useRef  } from 'react';
 import axios from 'axios';
import  './logincss.css';
import { UserMenu } from '../../elements/user_menu'
import { useSelector } from 'react-redux';
import { functions } from '../../functions';
import LoadingBar from "react-top-loading-bar";


export const Edit_profile = () => { 
 
const first_name = useRef()  
const last_name = useRef()  
const email = useRef()  
const gender_id = useRef()  
const [progress, setProgress] = useState(0);

const userData = useSelector( (userData)=>{
    return userData 
 })
 const [ userDashData, setuserDashData ] = useState([]);

 //console.log(userDashData);

 useEffect(async() => {
  let req = await axios.get("http://dev.propennyauction.com/api/edit_profile.php?user_id=" + userData.login.user_id );
  let res = await req;  
  if(res.data){
    setuserDashData(res.data.data);
  }
  functions.hide_dropdown(1);  
  setProgress(100);
  
},[]);

const gid = Number(userDashData.gender_id) 
 
async function postUserdata(){
  console.log('postUserdata function ')
  let fname =  first_name.current.value  
  let lname =  last_name.current.value  
  let e_mail =  email.current.value  
  let g_id =  gender_id.current.value  
 
  
  let postData = { fname,lname,e_mail,g_id }

  let req = await axios.post("http://dev.propennyauction.com/api/edit_profile.php?user_id=" + userData.login.user_id, JSON.stringify(postData) );
  let res = await req; 
   if(res.data.success){
    functions.flash_msg('<div class="center_popup">Records updated Successfully</div>');
  }

}


return(
<Fragment>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<UserMenu />   
 
<div className="doc_width shadow_bg input_line input_only_line user_edit_pge">
<div className="step_titel">
<h1>Edit profile</h1>
</div>		
<div id="rightcol"> 
<div className="rightcol_inner">

 
      
      <fieldset className="width_50 f_l edit_propfile">
 <div className="row">
        <div className="edit_user">
        <div className="input text focused">
          <label htmlFor="name">First Name*</label>
          <input name="first_name" ref={first_name} defaultValue={userDashData.first_name} className="text text_edt" />
                            </div>                 
        </div>


        
  <div className="edit_user">
           <div className="input text focused">
          <label htmlFor="name">Last Name*</label>
          <input name="last_name" ref={last_name}  defaultValue={userDashData.last_name} className="text text_edt" />
                            </div>
          </div>
  </div>
  
        
        
         <div className="row">
        <div className="edit_user">
         <div className="input text focused">
          <label htmlFor="name">Email*</label>
          <input name="email" ref={email} defaultValue={userDashData.email} className="text text_edt" />
                            </div>
          </div>
        
        
        <div className="edit_user">
         <div className="input date">                
        
          <label>Date of Birth*</label>
          <select name="days" defaultValue="31">
                                <option defaultValue="1">1</option>
                                <option defaultValue="2">2</option>
                                <option defaultValue="3">3</option>
                                <option defaultValue="4">4</option>
                                <option defaultValue="5">5</option>
                                <option defaultValue="6">6</option>
                                <option defaultValue="7">7</option>
                                <option defaultValue="8">8</option>
                                <option defaultValue="9">9</option>
                                <option defaultValue="10">10</option>
                                <option defaultValue="11">11</option>
                                <option defaultValue="12">12</option>
                                <option defaultValue="13">13</option>
                                <option defaultValue="14">14</option>
                                <option defaultValue="15">15</option>
                                <option defaultValue="16">16</option>
                                <option defaultValue="17">17</option>
                                <option defaultValue="18">18</option>
                                <option defaultValue="19">19</option>
                                <option defaultValue="20">20</option>
                                <option defaultValue="21">21</option>
                                <option defaultValue="22">22</option>
                                <option defaultValue="23">23</option>
                                <option defaultValue="24">24</option>
                                <option defaultValue="25">25</option>
                                <option defaultValue="26">26</option>
                                <option defaultValue="27">27</option>
                                <option defaultValue="28">28</option>
                                <option defaultValue="29">29</option>
                                <option defaultValue="30">30</option>
                                <option defaultValue="31">31</option>
                              </select>
          <select name="month" defaultdefaultValue="12">
                                <option defaultValue="01">January</option>
                                <option defaultValue="02">February</option>
                                <option defaultValue="03">March</option>
                                <option defaultValue="04">April</option>
                                <option defaultValue="05">May</option>
                                <option defaultValue="06">June</option>
                                <option defaultValue="07">July</option>
                                <option defaultValue="08">August</option>
                                <option defaultValue="09">September</option>
                                <option defaultValue="10">October</option>
                                <option defaultValue="11">November</option>
                                <option defaultValue="12">December</option>
                              </select>
          <select name="year" defaultdefaultValue="1984">
                                <option defaultValue="1970">1970</option>
                                <option defaultValue="1971">1971</option>
                                <option defaultValue="1972">1972</option>
                                <option defaultValue="1973">1973</option>
                                <option defaultValue="1974">1974</option>
                                <option defaultValue="1975">1975</option>
                                <option defaultValue="1976">1976</option>
                                <option defaultValue="1977">1977</option>
                                <option defaultValue="1978">1978</option>
                                <option defaultValue="1979">1979</option>
                                <option defaultValue="1980">1980</option>
                                <option defaultValue="1981">1981</option>
                                <option defaultValue="1982">1982</option>
                                <option defaultValue="1983">1983</option>
                                <option defaultValue="1984">1984</option>
                                <option defaultValue="1985">1985</option>
                                <option defaultValue="1986">1986</option>
                                <option defaultValue="1987">1987</option>
                                <option defaultValue="1988">1988</option>
                                <option defaultValue="1989">1989</option>
                                <option defaultValue="1990">1990</option>
                                <option defaultValue="1991">1991</option>
                                <option defaultValue="1992">1992</option>
                                <option defaultValue="1993">1993</option>
                                <option defaultValue="1994">1994</option>
                                <option defaultValue="1995">1995</option>
                                <option defaultValue="1996">1996</option>
                                <option defaultValue="1997">1997</option>
                                <option defaultValue="1998">1998</option>
                                <option defaultValue="1999">1999</option>
                                <option defaultValue="2000">2000</option>
                                <option defaultValue="2001">2001</option>
                                <option defaultValue="2002">2002</option>
                                <option defaultValue="2003">2003</option>
                                <option defaultValue="2004">2004</option>
                                <option defaultValue="2005">2005</option>
                                <option defaultValue="2006">2006</option>
                                <option defaultValue="2007">2007</option>
                                <option defaultValue="2008">2008</option>
                                <option defaultValue="2009">2009</option>
                                <option defaultValue="2010">2010</option>
                                <option defaultValue="2011">2011</option>
                                <option defaultValue="2012">2012</option>
                                <option defaultValue="2013">2013</option>
                                <option defaultValue="2014">2014</option>
                                <option defaultValue="2015">2015</option>
                                <option defaultValue="2016">2016</option>
                                <option defaultValue="2017">2017</option>
                                <option defaultValue="2018">2018</option>
                                <option defaultValue="2019">2019</option>
                                <option defaultValue="2020">2020</option>
                              </select>
          </div>
      	 </div>
 
 </div>
        
        
      <div className="edit_user">
        <div className="input select">          
          <label htmlFor="name">Gender*</label>
          <select ref={gender_id} defaultValue={gid || ""} >
                                <option value={1} > Male</option>
                                <option value={2} > Female</option>
          </select>
         </div> 
        </div>
        
        
        
        <div className="user_chekbox">
        <div className="input checkbox focused">
          <input type="checkbox" name="newsletter" defaultValue={userDashData.gender_id} />
          <label htmlFor="newsletter">Subscribe For Newsletter</label> 
         </div>
        </div>
          
</fieldset>
  <br style={{clear:"both"}} />
      <div className="submit"> 
      <input onClick={postUserdata} type="submit" name="submit" id="submit" defaultValue="Submit" className="send" style={{cursor:'pointer'}} />
    </div>
                
                                       
 </div>
</div>  
</div> 

</Fragment>
);	
}
