import React from 'react';

function Terms(props) {

    window.scrollTo(0, 0)
    
    return (
        <>
     
        
        <div className="doc_width shadow_bg inner_page">
		<div className="step_titel">
       		 <h1>Terms and Conditions</h1>
        </div>              
        <div id="rightcol" className="package_box package_page">
        
        
        <div class="padd_25">			
<p>
	<strong>·</strong><strong>&nbsp; Introduction</strong></p>
<p>
	Please read these Terms and Conditions (‘Terms of Service’,’Agreement’) carefully before using www.propennyauction.com (‘propennyauction’,’the Site’,’this Site’,’we’).This Agreement sets forth the legally binding terms and conditions for your (‘you’,’user’) &nbsp;use of the Site. By accessing or using this Site in any manner, including, but not limited to, visiting or browsing the Site, or bidding on the Site, you agree to be bound by these Terms of Service.</p>
<p>
	<strong>·</strong><strong>&nbsp; General</strong></p>
<p>
	To use propennyauction.com, you must not: provide propennyauction with inaccurate or incomplete information, violate any applicable federal and state laws or regulations, use this Site for any purpose that is prohibited by these terms and conditions, impersonate any person, collect or store personal data about other users of this Site, or engage in any other conduct that inhibits any other person from using or enjoying this Site. You also must verify that you are at least 18 years of age (or any other legal age required by the laws of your place of residence) and have the full capacity and ability to use this Site.</p>
<p>
	<strong>·</strong><strong>&nbsp; Registration</strong></p>
<p>
	To use some of the services or features made available to you on propennyauction.com, you will need to register. Upon registration, you are required to provide information about yourself that is true, accurate, current, and complete in all respects. Membership of propennyauction.com is only open to individuals for their own personal use. Membership is not open for commercial purposes, including retailers. Any individual may only register one membership. propennyauction reserves the right to restrict multiple registrations at one IP. propennyauction reserves the right to restrict the number of accounts per user and/or IP address, physical address, e-mail address. Membership is non-transferable. In particular, you must not allow others to use your membership and you must not transfer or sell your membership to another person. You must maintain control of your username and password and are responsible for any action taken while logged in as a member. You agree to notify us immediately at: info@propennyauction.com if you become aware of any unauthorized or improper use or disclosure of your member name and password, in which case propennyauction will issue you a new username and password. propennyauction shall not be held liable for the improper use of your membership, by you or any other party, specifically due to, but not limited to, allowing others to use your username and password, negligence, logging from unsecured computers/ devices. propennyauction will store a record of your transactions for a minimum of one year.</p>
<p>
	<strong>·</strong><strong>&nbsp; Eligibility to Purchase Bids</strong></p>
<p>
	By participating on propennyauction.com, you provide authority for us to perform credit verification from the payment processors listed, as we consider necessary in order to both authenticate identity or validate authorization of use of credit or debit card.</p>
<p>
	<strong>·</strong><strong>&nbsp; Cancellation of Membership</strong></p>
<p>
	If propennyauction reasonably believes that you have breached the Terms and Conditions, propennyauction has the absolute right to (i) revoke any winning bid that you have made. If propennyauction does so, propennyauction will refund you the value of the winning bid in case any payment has been made by you (but only the winning bid), and will award the auction to the member with the second highest bid; and/ or (ii) suspend, terminate or restrict your membership, in which case you are permanently banned from the website and cannot create a new account.</p>
<p>
	<strong>·</strong><strong>&nbsp; Purchasing Bids</strong></p>
<p>
	Different methods of obtaining bids include:<br/>
	(i) Free bids given upon registration<br/>
	(ii) Free bids given upon inviting friends<br/>
	(iii) Bids gifted by us due to user participation in promotions (for example twitter promotions)<br/>
	(iv) Bids won/bought on a bid pack auction<br/>
	(v) Bids purchased<br/>
	During an auction the bids are expended or used in the order they have been acquired, first in- first out.</p>
<p>
	<strong>·</strong><strong>&nbsp; Pricing Policy</strong></p>
<p>
	Prices shown on the Site are in USD. Bid package prices are subject to change due to user feedback or propennyauction’s decisions.</p>
<p>
	<strong>·</strong><strong>&nbsp; Payment</strong></p>
<p>
	To make purchases, you must have a current valid accepted payment method. Payment can be made through Visa, MasterCard, or Amex (“Card”) as well as Paypal. Payment will be debited and cleared from your account at time of purchase of the bid pack payment for your won auction. You also agree you may be subject to validation checks of your credit card with the card issuer.. Furthermore, while we take reasonable care to ensure your details and transactions are secure, we cannot be held liable for any loss you may suffer in the event of a third party gaining unauthorized access to any data provided unless negligence on our part can be demonstrated. You may not use any card which is not yours, or which you do not have the full authorization of its lawful owner/ holder to use.</p>
<p>
	<strong>·</strong><strong>&nbsp; How soon will I get my order?</strong></p>
<p>
	We aim to dispatch all orders within 24 hours of receiving payment. All physical products are shipped using USPS Ground, unless otherwise stated in the product description. However, unforeseen supply or shipping problems may occasionally result in delays. If there is a delay in shipping your order, we will contact you as soon as possible to notify you of the reason for the delay. We are not responsible for any delays that may be considered outside of our control. All physical products will require a signature upon receipt, unless otherwise stated in the product description. As we are unable to redirect orders once your items have been dispatched, please ensure you provide a suitable shipping address.</p>
<p>
	<strong>·</strong><strong>&nbsp; Returns</strong></p>
<p>
	Before shipping an item, several pictures of the product are taken. After you receive the item, if there are quality issues, inform propennyauction at charizard@propennyauction.com. Pictures of the item will be requested to ensure that the product has not been damaged or replaced. propennyauction reserves the right to refuse any returns if you fail to notify propennyauction within the two-day period. Products’ defects caused by the customer are not the responsibility of propennyauction. For queries related to returns, please contact us. If propennyauction determines that a return is in order, we will provide you with a return address. After we receive the returned product and perform a successful quality check, a refund of the post auction payment will be issued. Additionally, the bids spent on the auction will be credited to the account. For packs or boxes greater than 10 years old, no refunds or returns are allowed.</p>
<p>
	<strong>·</strong><strong>&nbsp; Intellectual Property</strong></p>
<p>
	The Site and its original content, features, and functionalities are owned by propennyauction.com and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. You may not use any content, feature, design, part, etc of the Site without the written permission of propennyauction.com.</p>
<p>
	<strong>·</strong><strong>&nbsp; Disclaimer and Limitation of Liability</strong></p>
<p>
	propennyauction.com does its best to ensure that all information and content on the Site is accurate. However, propennyauction.com gives no warranty or assurance about the content of the Site. As the Site is under constant development its contents may be out-of-date and subject to change without notice. propennyauction.com cannot be held liable, in any kind or way, for the accuracy of the content and information of the Site, or any harm or damage resulting from that at any given point in time. If you find any inaccurate information on the Site please contact us by sending an email to charizard@propennyauction.com and propennyauction.com will correct it, where propennyauction.com agrees, as soon as practicable.<br/>
	<br/>
	Neither propennyauction.com nor any of its agents, shareholders, managers, suppliers, web developers, employees or sub-contractors shall be liable to you or any other party for any claim, loss, demand or damages whatsoever (whether such claims, loss, demands or damages were foreseeable, known or otherwise) arising out of or in connection with the use of this Site, or information, content, or materials included on the Site. In no event shall propennyauction.com or any of its agents, shareholders, managers, suppliers, web developers, employees and sub-contractors be liable to you for any indirect or consequential loss or damage including, but not limited to, any: loss of actual or anticipated profits loss of revenue; loss of opportunity; loss of anticipated savings; loss of good will; loss of reputation; loss or damage to or corruption of data; loss of use of money or otherwise.</p>
<p>
	<strong>·</strong><strong>&nbsp; Termination</strong></p>
<p>
	We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. propennyauction.com shall not be held liable for any claim, loss, demand or damages to you or any other party resulting from such termination.</p>
<p>
	<strong>·</strong><strong>&nbsp; Changes to This Agreement</strong></p>
<p>
	We reserve the right, at our sole discretion, to modify or replace these Terms of Service by posting the updated terms on the Site along a notification on the main page of the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms of Service. Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site.</p>
<p>
	<strong>·</strong><strong>&nbsp; Governing Law and jurisdiction</strong></p>
<p>
	This Agreement (and any further rules, polices, or guidelines incorporated by reference) shall be governed and construed in accordance with the United States laws without giving effect to any principles of conflicts of law.<br/>
	<br/>
	Likewise, the competent courts of the United States shall have exclusive jurisdiction in relation to all disputes that may arise between the Parties in relation to or in connection with this Agreement.</p>
<p>
	<strong>·</strong><strong>&nbsp; Warranties</strong></p>
<p>
	We warrant that<br/>
	(i) the Merchandise will comply in all material respects with any descriptions given by us on the Website;<br/>
	(ii) All merchandise on the Site is 100% Authentic and 100% original brand</p>
<p>
	<strong>·</strong><strong>&nbsp; Content</strong></p>
<p>
	Although propennyauction.com strives at all times to maintain the accuracy of information maintained on the Site, including pricing information and product details, occasionally pricing or other information errors may occur on this Website. In the event that a product that is available through our Website is mistakenly listed at an incorrect price or with other incorrect information, propennyauction.com reserves the right to refuse or cancel any orders placed for any product listed at the incorrect price or based on incorrect product information.</p>
<p>
	<strong>·</strong><strong>&nbsp; Your Acceptance of These Terms</strong></p>
<p>
	By creating an account on propennyauction.com, you agree to all of these Terms &amp; Conditions.</p>
<p>
	<br/>
	&nbsp;</p>
</div>        
        </div>
        </div> 
        
        </>
    );
}

export default Terms;