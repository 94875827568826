import React, { Fragment,useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

export const Unique_auction_guide = () => {
     const [progress, setProgress] = useState(100);

return(
<Fragment>
<LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<div className="doc_width shadow_bg inner_page">
		<div className="step_titel">
          <h1>Unique Auction Guide</h1>      
        </div> 
                
<div id="rightcol">


<p>this is how it Unique auction guide page</p>  
<p>this is how it Unique auction guide works page </p> 

               
</div>
</div> 

</Fragment>
);	

}