import React, { useState  } from 'react';
import LoadingBar from "react-top-loading-bar";

 
const Contact = (props) => {

    window.scrollTo(0, 0)
    const [progress, setProgress] = useState(100);

    return (
        <>
        <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

        <div className="step_titel" style={{marginBottom: 'px'}}>
        <div className="doc_width">
        <h1>Contact Us</h1>
        
        </div>
        </div>
        
        <div id="container" className="user-container user-container-login contact">
         <div id="login" className="outline_btn page_login">
               
         <div className="centre_box login_box fadeInDown animated">

         <div className="login_box_right">
             <a href="/"><span className="close-btn">X</span></a>
             
        <div className="login-only padd_40">
        <div className="text-center">         
        <div className="dont_have_text">
        <span className="dont_have">Have a question? Let us help!</span>        
        </div>
        </div>


<form method="post" action="/contact"><fieldset style={{textTransform: 'uppercase'}}> 
    
    <input type="hidden" name="_method" value="POST" /></fieldset>
	<div className="input text"><label for="PageName">Name</label>
    <input name="data[Page][name]" type="text" maxlength="255" value="" id="PageName"/></div><div className="input text"><label for="PageEmail">Email</label>
    <input name="data[Page][email]" type="text" className="disabled" value="" id="PageEmail"/></div><div className="input text"><label for="PagePhoneNumber">Phone Number</label>
    <input name="data[Page][phone_number]" type="text" className="type_phone2" maxlength="15" value="" id="PagePhoneNumber"/></div><div className="input textarea"><label for="PageMessage">Message</label><textarea name="data[Page][message]" cols="30" rows="6" id="PageMessage"></textarea></div>	
					
	<div className="submit"><input type="submit" value="Submit" /></div></form>

</div> 
</div>
</div>                       
</div> 
</div>


        
        </>
    );
}

export default Contact;