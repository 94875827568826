import React, { useEffect, Fragment, useState } from "react";
import { UserMenu } from "../../elements/user_menu";
import { functions } from "../../functions";
import LoadingBar from "react-top-loading-bar";

export const Package = () => {
  functions.hide_dropdown(1);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(100);
  }, []);

  return (
    <>
     <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

      <UserMenu />

      
<div className="doc_width shadow_bg">
<div className="step_titel">    
        <h1>Membership Packages</h1>              
      </div> 
				<div id="rightcol" className="package_box package_page text-center">
				<h2> Which Package Is Right For You?</h2>
				<div className="perfect_text">Perfect For Beginners  </div>
				
					   <ul>
							
					<form action="payment.html" method="post" name="formulario">
							 
						   <li>              	
							<label>Bolsa Mini</label>
				   <div className="pack_img"><img src="images/bids_30.png" /></div>
							<span className="hide"><strong>30</strong> Bids</span>				 	<p>  $1.00 </p>
							   <div className="submit"> 						
						<input type="submit" name="submit_package_1" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
						   </div>                                              
							</li> 
					   
						
							 
						   <li>              	
							<label>Bolsa Chica</label>
				   <div className="pack_img"><img src="images/bids_50.png" /></div>
							<span className="hide"><strong>50</strong> Bids</span>				 <p>  $10,000.00 </p>
						   <div className="submit"> 
								   
						<input type="submit" name="submit_package_2" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
					   </div>                                              
					   </li> 
					   
						<li>              	
							<label>Bolsa Mediana</label>
				   <div className="pack_img"><img src="images/bids_100.png" /></div>
							<span className="hide"><strong>100</strong> Bids</span>				 <p>  $20,000.00 </p>
							   <div className="submit"> 
								   
						<input type="submit" name="submit_package_3" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
						   </div>                                              
						   </li> 
					   
						
							 
						   <li>              	
							<label>Bolsa Rellenita</label>
				   <div className="pack_img"><img src="images/bids_500.png" /></div>
							<span className="hide"><strong>500</strong> Bids</span>				 <p>  $30,000.00 </p>
						   <div className="submit"> 
								   
						<input type="submit" name="submit_package_6" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
						   </div>                                              
							</li> 
					   
						
							 
						   <li>              	
							<label>Bolsa Grande</label>
				   <div className="pack_img"><img src="images/bids_1000.png" /></div>
							<span className="hide"><strong>1000</strong> Bids</span>				 <p>  $50,000.00 </p>
						   <div className="submit"> 
								   
						<input type="submit" name="submit_package_4" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
						   </div>                                              
						   </li> 
					   
						
							 
						   <li>              	
							<label>Bolsa Monstruosa</label>
				   <div className="pack_img"><img src="images/bids_3000.png" /></div>
							<span className="hide"><strong>3000</strong> Bids</span>				 <p>  $100,000.00 </p>
						   <div className="submit"> 						
						<input type="submit" name="submit_package_5" id="submit" value="Purchase Using Paypal!" className="send" style={{cursor:'pointer'}} />
						   </div>                                              
						   </li> 
					   </form>  
					   </ul>
				   </div>
			 </div>
    </>
  );
};
