import React from 'react';
import {NavLink} from 'react-router-dom'  
 

export const AddressIndexInclude = (props) => {
 
return(
<>
<div className="doc_width shadow_bg1"> 
    
    <div id="rightcol">
   <div className="rightcol_inner text-center">
                    
   <div className="address_table shipping">				
   <h1>Shipping Address</h1>
    <span className="green_line"></span>
   <br />
     <br />
   
   <div className="row">
   <strong>Name : </strong> {props.userShipping.first_name} </div>

   <div className="row">
   <strong> Last Name : </strong> {props.userShipping.last_name} </div>
   
   
   <div className="row">
   <strong>Address : </strong>  { props.userShipping.address_1 }
              </div>		  
   
    <div className="row">
    <strong>City : </strong> { props.userShipping.city }</div>
   
   
   <div className="row">
   <strong>State : </strong>  { props.userShipping.state } </div>
   
   <div className="row">
    <strong>Zip Code:  </strong> { props.userShipping.postcode } </div>
   
   <div className="row">
   <strong>Country:</strong>  { props.userShipping.country }  </div>
   
   
   
   
   <div className="row">
   <strong>Phone : </strong>  { props.userShipping.phone } </div>
   
   
   <div className="row"><strong>Email : </strong> { props.userShipping.email }   </div>
   
            <br style={{clear:'both'}} />
   <div className="buttons"> 
   <button onClick={ ()=>props.setEditProfile('Shipping') } className="btn_new animated-btn animated-1 small_btn" to="/">Edit Address</button>
   </div>
   </div>
    
   
   
 
   <div className="address_table billing">
   <h1>Billing Address</h1>
   <span className="green_line"></span>
   <br />
    <br />
   
  <div className="row">
   <strong>Name : </strong> { props.userBilling.first_name } </div>

   <div className="row">
   <strong> Last Name : </strong> { props.userBilling.last_name }  </div>
   
   
   <div className="row">
   <strong>Address : </strong>  { props.userBilling.address_1 }
              </div>		  
   
    <div className="row">
    <strong>City : </strong>  { props.userBilling.city } </div>
   
   
   <div className="row">
   <strong>State : </strong> { props.userBilling.state }55</div>
   
   <div className="row">
    <strong>Zip Code:  </strong>  { props.userBilling.postcode } </div>
   
   <div className="row">
   <strong>Country:</strong> { props.userBilling.country } </div>
   
   
   
   
   <div className="row">
   <strong>Phone : </strong> { props.userBilling.phone } </div>
   <div className="row"><strong>Email : </strong>  { props.userBilling.email }		  </div>
   
   
        
   
               
   <br style={{clear:'both'}} />
   <div className="buttons"> 
   <button onClick={ ()=>props.setEditProfile('Billing') } className="btn_new animated-btn animated-1 small_btn" to="/">Edit Address</button>
   </div>
   </div>
   
   </div>
   </div>		 
   


 </div>

 
</>
) 
}