import React, { Fragment , useState} from "react";
import { functions } from "../../functions";
import LoadingBar from "react-top-loading-bar";

export const How_it_works = () => {
	const [progress, setProgress] = useState(100);

  functions.hide_dropdown(1);
  window.scrollTo(0, 0);
  return (
    <Fragment>
     <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

      <div className="doc_width shadow_bg inner_page">
        <div className="step_titel">
          <h1>How it works </h1>
        </div>
        <div id="rightcol" className="package_box package_page">
          <div className="page_list">
            <div className="padd_25">
              <p>
                <strong>
                  ** DO NOT WAIT UNTIL THERE IS 1 OR 2 SECONDS LEFT TO BID!
                  DIFFERENT INTERNET CONNECTIONS CAN CAUSE LAG AND YOU WILL
                  LIKELY MISS OUT!!**
                </strong>
              </p>
              <p>
                <b>** INSTEAD TRY USING THE BID BUDDY **</b>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>How do I get started?</strong>
              </p>
              <p>
                To get started you need to create an account. You should
                immediately receive a confirmation email. Click the link in the
                email to confirm your account.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>How do I participate in auctions?</strong>
              </p>
              <p>
                You will need bids to participate in auctions, so purchase the
                bid package that suits you best or use your free bids. Bids act
                as credits that allow you to partake
              </p>
              <p>
                in auctions. For each bid placed, one bid credit will be
                deducted from your account. Click “Bid Now” on the Home page or
                product page to use a bid. Upon using
              </p>
              <p>
                a bid, you will win the auction if the clock expires and no one
                else bids
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>How do the auctions work?</strong>
              </p>
              <p>
                Each auction has an expected end time and a clock that counts
                down to zero. Each auction starts at $0.00 and each bid will
                increase the price by $0.01. If a bid
              </p>
              <p>
                is placed when the clock is below 00:00:10, the clock will be
                reset to 00:00:10. The auction is over when the clock reaches
                00:00:00, and the user with the last
              </p>
              <p>bid wins the auction.</p>
              <p>&nbsp;</p>
              <p>
                <strong>How do I acquire bids?</strong>
              </p>
              <p>
                Users can acquire bids by purchasing bid packages, taking
                advantage of different promotions, or referring friends. Bid
                packages can be purchased at
              </p>
              <p>
                <a href="http://propennyauction.com/packages">
                  http://propennyauction.com/packages
                </a>
                . Bids can be purchased using PayPal or a debit/credit card. If
                there is any issue processing payment or crediting bids, contact
              </p>
              <p>
                support at{" "}
                <a href="mailto:info@propennyauction.com">
                  info@propennyauction.com
                </a>
                .
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>What are the next steps after I win an auction?</strong>
              </p>
              <p>
                Fill out your shipping information and pay the auction ending
                price. The product will be shipped, for free, via USPS First
                Class or USPS Priority within one day of receiving payment
              </p>
              <p>
                and shipping information.
                <br />
                <br />
                &nbsp;
              </p>
              <p>
                <strong>What is the best strategy to win auctions?</strong>
              </p>
              <p>
                propennyauction.com is not a game of chance or gambling; it is a
                game of strategy. The users with the most success
                typically&nbsp; use the Bid Buddy, and often times
              </p>
              <p>
                take advantage of auctions with lower bidding activity. Unless
                using the Bid Biddy, you may miss out if you wait till the last
                second to bid. The “bid history” table
              </p>
              <p>
                on the product page allows bidders to see which other users are
                bidding. This can help you understand your competition and
                develop strategies. Participating
              </p>
              <p>
                in many random auctions might be appealing, but it will lower
                your success rate. Pick a product or two that you want to go
                after, and put your focus towards
              </p>
              <p>
                them. Set a bid budget for a given product and stick to it.
                Familiarizing yourself with the FAQ’s and Terms &amp; Conditions
                will provide you with useful insight on
              </p>
              <p>auction mechanics.</p>
              <p>&nbsp;</p>
              <p>
                Still have questions? Check out the &nbsp;
                <a
                  href="https://www.propennyauction.com/frequently-asked-questions"
                  style={{
                    fontFamily: "Arial",
                    font: "sansSerif",
                    fontSize: "9pt",
                  }}
                >
                  FAQ's
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  ** DO NOT WAIT UNTIL THERE IS 1 OR 2 SECONDS LEFT TO BID!
                  DIFFERENT INTERNET CONNECTIONS CAN CAUSE LAG AND YOU WILL
                  LIKELY MISS OUT!!**
                </strong>
              </p>
              <p>
                <b>** INSTEAD TRY USING THE BID BUDDY **</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
