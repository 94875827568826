import { useDispatch } from 'react-redux';

const Logout = () => {

const dispatch = useDispatch();

window.localStorage.clear();

let SET_USER_ID = 'SET_USER_ID';
let SET_USERNAME = 'SET_USERNAME';
let SET_EMAIL = 'SET_EMAIL';
dispatch({type:  SET_USER_ID, payload: 0 }); 
dispatch({type: SET_USERNAME, payload: '' });
dispatch({type: SET_EMAIL,    payload: '' });
	

return null;
} 

export default Logout;