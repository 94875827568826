import React, { Fragment, useState, useEffect  } from 'react';
 import axios from 'axios';
import  './logincss.css';
import { UserMenu } from '../../elements/user_menu'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { functions } from '../../functions';
import LoadingBar from "react-top-loading-bar";

 //  userData.user_id

export const User = () => {
	const userData = useSelector( (userData)=>{
		 return userData 
	})

	const [ userDashData, setuserDashData ] = useState([]);

	const [progress, setProgress] = useState(0);
	useEffect(() => {
	  setProgress(100);
	}, []);
 


return(
 <>
 <LoadingBar color="#FF0000" progress={progress}  height={2} onLoaderFinished={() => setProgress(0)}  />

<UserMenu />

 
<div className="doc_width shadow_bg">

<div className="step_titel">
<h1>DashBoard</h1>
</div>

<div className="user-box text-center">

<div className="row mar_b_20">

<div className="simple_box">
<div className="padd_25 user_box_1 radius">
<p className="simple_box_title">You currently have </p>
<p className="box_p">		
<strong>{userDashData.bids}</strong>bids.
</p>
<p>
<NavLink to="/user_package" className="btn_new  left-animated  btn-blue">Purchase some bids</NavLink> 		 
</p></div>
</div> 
	
<div className="simple_box">
<div className="padd_25 user_box_2 radius">
<p className="simple_box_title">You currently have unpaid</p>
<p className="box_p"><strong>{userDashData.unpaid_auction}</strong>auction.</p>
<p>

<NavLink to="/won_auctions" className="btn_new  left-animated  btn-blue">View your won auctions</NavLink>
</p>

</div>
</div>	


<div className="simple_box">
<div className="padd_25 user_box_3 radius">
<p className="simple_box_title">User Registration Information</p>
<p className="box_p"><strong>{ userData.login.username } </strong> 
Username.
</p>
<p>
<NavLink to="/edit_profile" className="btn_new  left-animated  btn-blue">View User Details</NavLink>

</p>
</div>
</div>	

</div>
</div>

<div className="inner_heading text-center"><h1>Things to do</h1></div>
<div id="rightcol">
<ul className="to-do">


<li>
<NavLink to="/address" className="btn_new  animated-btn animated-1">Add Shipping Address</NavLink>

</li>


<li>
<NavLink to="/won_auctions" className="btn_new  animated-btn animated-1">Pay for Auction</NavLink>
</li>

<li>
<NavLink to="/store" className="btn_new  animated-btn animated-1">Store Information</NavLink>

 <NavLink to="/store_orders" className="btn_new  animated-btn animated-1">Store Orders</NavLink>

</li>

</ul>
</div>
</div>
</>
 
);	

}