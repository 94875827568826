import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Index } from './view/auctions/index'
import { Login } from './view/users/Login'
import { Signup } from './view/users/Signup'
import Logout from './view/users/Logout';
import {Package} from './view/packages/index';
import {Wheel} from './view/pages/wheel';
import { How_it_works } from './view/pages/how_it_works';
import { Penny_auction_guide } from './view/pages/penny_auction_guide';
import { Seat_auction_guide } from './view/pages/seat_auction_guide';
import { Unique_auction_guide } from './view/pages/unique_auction_guide';
import { Ebay_auction_guide } from './view/pages/ebay_auction_guide';

import { User } from './view/users/index';
import { Edit_profile } from './view/users/edit_profile';
import { Change_Password } from './view/users/change_password';
import { Watch_list } from './view/users/watchList';
import { Address } from './view/addresses/index';
import { User_package } from './view/users/packages';
import { Bid_buttler } from './view/users/bid_buttler';
import { My_bids } from './view/bids/index';
import { Won_auctions } from './view/auctions/won';
import { Accounts } from './view/accounts/accounts';
import { InviteFriend } from './view/users/inviteFriend';
import { Auction_View } from './view/auctions/view';
import Store from './view/store/index';
import Store_order from './view/store/store_orders';
import Store_order_view from './view/store/store_order_view';
import Cart from './view/store/cart';
import Product_View from './view/store/view';
import Contact from './view/pages/contact';
import Privacy from './view/pages/privacy'; 
import Terms from './view/pages/terms';  
import About from './view/pages/about';
import Reset from './view/users/reset';
import Contact1 from './view/pages/contact';

export const CartCountContext = React.createContext();
const CartCountProvider = CartCountContext.Provider;
// import   Test  from './view/pages/test';
// import NameList from './view/Test/NameList';

const Router = (props) => {

return(
<React.Fragment>
<CartCountProvider value={ props }>
 <Switch>
    <Route exact path="/" component={Index}  />
    <Route exact path="/auctions/view/:auction_id" component={Auction_View}  />
    <Route exact path="/home/:auction_type" component={Index}  />
    <Route exact path="/store" component={Store}  />
    <Route exact path="/store/:cat_id" component={Store}  />

    <Route exact path="/products/view/:product_id" component={Product_View}  />

    <Route exact path="/cart" component={Cart}  />
    <Route exact path="/login" component={Login}  />
    <Route exact path="/signup" component={Signup}  /> 
    <Route exact path="/logout" component={Logout}  /> 
    <Route exact path="/packages" component={Package}  /> 
    <Route exact path="/wheel" component={Wheel}  /> 
    <Route exact path="/how_it_works" component={How_it_works}  /> 
    <Route exact path="/penny_auction_guide" component={Penny_auction_guide}  /> 
    <Route exact path="/seat_auction_guide" component={Seat_auction_guide}  /> 
    <Route exact path="/unique_auction_guide" component={Unique_auction_guide}  /> 
    <Route exact path="/ebay_auction_guide" component={Ebay_auction_guide}  /> 

    <Route exact path="/users" component={User}  /> 
    <Route exact path="/edit_profile" component={Edit_profile}  /> 
    <Route exact path="/change_password" component={Change_Password}  /> 
    <Route exact path="/watch_list" component={Watch_list}  />
    <Route exact path="/address" component={Address}  />
    <Route exact path="/user_package" component={User_package}  />
    <Route exact path="/bid_butlers" component={Bid_buttler}  />
    <Route exact path="/my_bids" component={My_bids}  />
    <Route exact path="/won_auctions" component={Won_auctions}  />
    <Route exact path="/accounts" component={Accounts}  />
    <Route exact path="/invite-friend" component={InviteFriend}  />
    <Route exact path="/contact" component={Contact}  />
    <Route exact path="/privacy" component={Privacy}  />
    <Route exact path="/terms" component={Terms}  />
    <Route exact path="/about" component={About}  /> 
    <Route exact path="/reset_pass" component={Reset}  />
    <Route exact path="/store_orders" component={Store_order}  />
    <Route exact path="/contact1" component={Contact1}  />
    <Route exact path="/store/orders/:order_id" component={Store_order_view}  />
      {/* <Route exact path="/name" component={NameList}   />  */}
     <Route render={() => <div class="main_error"><div class="error_inner"> Sorry Page not found  1 </div></div>}/>
 </Switch>
 </CartCountProvider>
</React.Fragment>
);	
}


export default Router;