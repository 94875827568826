import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

export const Header = (props) => {
  const userData = useSelector((userData) => userData);

  const [home_url, sethome_url] = useState(Math.floor(Math.random() * 10));

  const menuClick = (event) => {
    props.settopLoading(0);
    console.log("toploading 0 ");
  };

  function update_home_url() {
    sethome_url("/home/" + Math.floor(Math.random() * 10));
  }

  return (
    <Fragment>
      <div className="fix_header wow fadeInDown animated">
        <div id="header">
          <div className="doc_width">
            <div className="logo" onMouseOver={update_home_url}>
              <NavLink to={home_url}>
                {" "}
                <img src="/images/logo.png" alt="" border="0" />{" "}
              </NavLink>
            </div>

            <div className="header_right">
              <div className="login_menu">
                <div className="link_center">
                  <li>
                    <NavLink to="/home/Live" onClick={() => menuClick()}>
                      All Auctions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/home/Closed" onClick={() => menuClick()}>
                      Winners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/packages" onClick={() => menuClick()}>
                      Buy Bids
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/store" onClick={() => menuClick()}>
                      Store
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/how_it_works" onClick={() => menuClick()}>
                      Help
                    </NavLink>
                  </li>
                </div>
                {userData.login.user_id > 0 ? (
                  <Fragment>
                    <li className="bid_icon">
                      <NavLink to="/cart">
                        <img src="/images/bid_icon.png" alt="" border="0" />
                        <span>Blance</span>
                        <p className="bid-bal">500</p>
                      </NavLink>
                    </li>
                    <li className="welcomeuser">
                      <font>Hello,</font>{" "}
                      <strong>{userData.login.username}</strong>
                      <p class="account-link">
                        <NavLink class="link_account" to="/users">
                          My Account
                        </NavLink>
                        <NavLink class="logout_link" to="/logout">
                          Logout
                        </NavLink>
                      </p>
                    </li>
                    <li className="cart">
                      <NavLink to="/cart">
                        <img src="/images/cart-icon.png" alt="" border="0" />
                        <span>{props.cartCnt}</span>
                      </NavLink>
                    </li>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="right_link">
                      <li>
                        <NavLink className="header-btn" to="/login">
                          Login
                        </NavLink>
                        <NavLink className="header-btn" to="/signup">
                          Register
                        </NavLink>
                      </li>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <div class="main_menu mobile_none">
          <div class="doc_width">
            <ul id="#navigation" className="slide_login">
              {userData.login.user_id > 0 ? (
                <Fragment>
                  <li className="tasktop_non welcome_name">
                    <div className="bid_icon">
                      <NavLink to="/cart">
                        <img src="/images/bid_icon.png" alt="" border="0" />
                        <span>Blance</span>
                        <p className="bid-bal">500</p>
                      </NavLink>
                    </div>
                    <NavLink to="/users">
                      {" "}
                      Hello, <strong>{userData.login.username}</strong>{" "}
                    </NavLink>
                    <span className="cart">
                      <NavLink to="/cart">
                        <img src="/images/white_cart.png" alt="" border="0" />
                        <span>10</span>
                      </NavLink>
                    </span>
                  </li>
                  <li className="header_btn_link">
                    <NavLink
                      className="btn_new left-animated  btn-blue small_btn"
                      to="/users"
                    >
                      My Account
                    </NavLink>
                    <NavLink
                      className="btn_new left-animated  btn-blue btn-logout small_btn"
                      to="/logout"
                    >
                      Logout
                    </NavLink>
                  </li>
                </Fragment>
              ) : (
                <Fragment>
                  <li className="header_btn_link">
                    <NavLink
                      className="btn_new left-animated  btn-blue small_btn"
                      to="/login"
                    >
                      Login
                    </NavLink>
                    <NavLink
                      className="btn_new left-animated  btn-blue small_btn"
                      to="/signup"
                    >
                      Register
                    </NavLink>
                  </li>
                </Fragment>
              )}
              <li>
                <NavLink to="/home/Live" onClick={() => menuClick()}>
                  All Auctions
                </NavLink>
              </li>
              <li>
                <NavLink to="/home/Closed" onClick={() => menuClick()}>
                  Winners
                </NavLink>
              </li>
              <li>
                <NavLink to="/packages" onClick={() => menuClick()}>
                  Buy Bids
                </NavLink>
              </li>
              <li>
                <NavLink to="/store" onClick={() => menuClick()}>
                  Store
                </NavLink>
              </li>
              <li>
                <NavLink to="/how_it_works" onClick={() => menuClick()}>
                  Help
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className={`loading width_${props.topLoading} hide`}></div>
      </div>

      <div
        id="flash_msg"
        className="flash_msg main_error"
        style={{ display: "none" }}
      >
        <div id="error_inner" className="error_inner"></div>
      </div>
    </Fragment>
  );
};
