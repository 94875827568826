import React, { useRef } from 'react';
import { UserMenu } from '../../elements/user_menu'; 
import { useSelector } from 'react-redux';
import {functions} from  '../../functions';
import axios from 'axios';

export const InviteFriend = () => {
        
 const emailsR = useRef()
 const msgR = useRef()

 functions.hide_dropdown(1);  

const userData = useSelector( (userData)=>{
  return userData 
})

const PostData = async()=>{
        console.log('in post dat')
       const  emails = emailsR.current.value ;
       const  message = msgR.current.value ;
        let postData = {  emails,message  }

        let req = await axios.post("http://dev.propennyauction.com/api/invite_frnd.php?user_id=" + userData.login.user_id, JSON.stringify(postData));
        let res = await req;
        console.log(res);
        if(res.status === 200){
                functions.flash_msg(`Invitation sent Successfully`);  
        }

}


return(
<> 


<UserMenu />    

<div className="doc_width shadow_bg">
<div className="step_titel">
  <h1>Invite Friend</h1>     
</div>        
<div id="rightcol">
<div className="invites">
<div className="invite_left">
<div className="invite_left_inner">
 

<h3 className="billing">You will receive 10% free bids on the first package any of your friends purchase.</h3>
<textarea className="tex-are" ref={emailsR} rows="5" cols="50"></textarea>


<h3 className="billing">example: friend1@mail.com, friend2@mail.com, friend3@mail.com</h3>


    
<textarea className="tex-are" ref={msgR} rows="5" cols="50">Please Sign Up at Xbay to receive great deals on a lot of products.</textarea>
    





<div className="submit">
<button className="hide" onClick={PostData} > Invite Your Friends </button>
<input onClick={PostData}  type="submit" name="submit" id="submit" value="Invite Your Friends"  />
</div>


 
</div>
</div>


<div className="invite_right">
<img src="images/invite_bnr.jpg" alt="" />
</div>


</div> 
        </div>    

</div>
</>
)    
}