import React, { useRef } from 'react';
import axios from 'axios'
import {functions} from  '../../functions';


export const EditAddressInclude = (props) => {
 
  //functions.hide_dropdown(1); 

  const address_type = props.address_type;
  let shipData;let address_type_id;
  if (address_type === 'Shipping') {
    shipData = props.userShipping;
    address_type_id = 1;
  }
  if (address_type === 'Billing') {
    shipData = props.userBilling; 
    address_type_id = 2;
  }

  const first_nameR = useRef()
  const last_nameR = useRef()
  const emailR = useRef()
  const address_1R = useRef()
  const cityR = useRef()
  const stateR = useRef()
  const countryR = useRef()
  const postcodeR = useRef()
  const phoneR = useRef()

  const userData = props.userData;


  const PostAddressData = async() => {
   
  const first_name = first_nameR.current.value
  const last_name = last_nameR.current.value
  const email = emailR.current.value
  const address_1 = address_1R.current.value
  const city = cityR.current.value
  const state = stateR.current.value
  const country = countryR.current.value
  const postcode = postcodeR.current.value
  const phone = phoneR.current.value
  const address_id = shipData.address_id 
  console.log(address_type_id)
 
  let postData = { first_name,last_name,email,address_1,city,state, country,postcode,phone,address_id,address_type_id  }

    let req = await axios.post("http://dev.propennyauction.com/api/address_add_edit.php?user_id=" + userData.login.user_id, JSON.stringify(postData));
    let res = await req;
    console.log(res);
    if(res.status === 200){
      props.setEditAddress(false);
      functions.flash_msg(`${address_type} Address Updated Successfully`);  

    }

  }

  return (
    <>


      <div className="doc_width shadow_bg input_line input_only_line user_edit_pge">
        <div id="rightcol">
          <div className="rightcol_inner">



            <fieldset className="width_50 f_l edit_propfile">

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">First Name* {props.address_type} </label>
                    <input ref={first_nameR} defaultValue={shipData.first_name} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Last Name*   </label>
                    <input ref={last_nameR} defaultValue={shipData.last_name} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Email*   </label>
                    <input ref={emailR} defaultValue={shipData.email} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Address *   </label>
                    <input ref={address_1R} defaultValue={shipData.address_1} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">city *   </label>
                    <input ref={cityR} defaultValue={shipData.city} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">State *   </label>
                    <input ref={stateR} defaultValue={shipData.state} className="text text_edt" />
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Zipcode *   </label>
                    <input ref={postcodeR} defaultValue={shipData.postcode} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Country *   </label>
                    <input ref={countryR} defaultValue={shipData.country} className="text text_edt" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="edit_user">
                  <div className="input text focused">
                    <label htmlFor="name">Phone *   </label>
                    <input ref={phoneR} defaultValue={shipData.phone} className="text text_edt" />
                  </div>
                </div>
              </div>


            </fieldset>


            <br style={{ clear: "both" }} />
            <div className="buttons">
              <button onClick={() => PostAddressData()}  > Update Address </button>
            </div> <br /><br /><br />



          </div>
        </div>
      </div>

    </>


  )
}